import React, { Component } from "react";
import * as API from "../api.js";
import "../style.css";
import shareIcon from "../icons/share.png";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import VideoPlayer from "../VideoPlayer.js";
import { Modal } from "react-bootstrap";
import Map from "../MapComponent.js";
import qs from 'qs';
import viewFullscreenFeedButton from '../icons/allFeedsButton.png';
import KafkaListener from '../KafkaListener.js';
import AltitudeIcon from '../res/displacement.svg';
import AttitudeIcon from '../res/drone.svg';
import DirectionIcon from '../res/compass.svg';
import Popup from "reactjs-popup";
import LocationIcon from '../res/locationMarker.svg';
import BatteryIcon from '../res/battery.svg';
import ModeIcon from '../res/mode.svg';
import SatellitesIcon from '../res/satellite.svg';
import VelocityIcon from '../res/meter.svg';
import DCCFlyViewUserPinger from '../DCCFlyViewUserPinger'
import waiting from '../res/waiting.png';
import closeButton from '../close-button.png';
import { connect } from "react-redux";
import max from '../icons/liveFeedmax.png'
import DroneWithBg from "../icons/DroneWithBg.svg"
import { SetRestrictSesssionExpire } from "../helperComponents/OnIdleHandlerWrapper.js";
import LoaderComponent from "../ReusableComponents/LoaderComponent.js";
import Tippy from "@tippyjs/react";
import 'tippy.js/dist/tippy.css'; // optional
import AppConstants from "../AppConstants.js";
import { addUserAnalytic, checkURLExist, convertMode, getStatusElement, headingToDirection, updateOutTime } from "../ReusableComponents/reusableFunctions.js";
import VideoPlayBack from "../ReusableComponents/VideoPlayBack.js";
import waterDrop from "../res/Drop.svg";
import acreIcon from "../res/acre.svg";
import distanceIcon from "../res/distance.svg";
import windIcon from "../res/windIcon.svg";
import wave from "../res/wave.svg";
import locDistance from "../res/locDistance.svg";
import RecentFligthsReplay from "../AeroGCSEnterprise/Components/FligthsReplay/RecentFligthsReplay.js";
import dateFormat from 'dateformat'

let forwardCount = 0;
class AdminMainPageComponent extends Component {
  state = {
    currSliderTime: 0,
    replaySpeed: null,
    replay_player_start_time: 0,
    replay_player_end_time: 0,
    disableBackBtn: true,
    timeoutIds: [],
    user: {},
    type: "",
    loading: true,
    shareLinkText1: "",
    shareLinkText2: "",
    sharableLink: "",
    showLinkPopup: false,
    cipied: false,
    title: "ALL",
    refresh: false,
    flightReplayMode: false,
    drones: {},
    mapKey: 1,
    flightID: "",
    pageNumber: 0,
    activeUsersCount: 0,
    newPlan: {},
    mini: false,
    showNetworkStatsPopup: false,
    bandwidth: 1000000,
    overallConnectivity: -1,
    trailLogs: [],
    projectName: '',
    planName: '',
    logs: [],
    buttonClicked: {
      takeOff: false,
      land: false,
      hover: false,
      rtl: false,
      camOn: false,
      camOff: false
    },
    readyForListening: true,
    flightStatus: <p style={{ color: "green" }}>Status: OK</p>,
    min: false,
    drone: '',
    recentReplaySidebar: true,
    activeComponent: true,
    firstLoad: true,
    outId: "",
    selectedUser:'',
  };

  componentWillUnmount() {
    SetRestrictSesssionExpire(false)
    clearInterval(this.interval)
    clearInterval(this.checkStatusInterval)
    clearInterval(this.streamKeyInterval)
    updateOutTime(this.state.outId)
  }

  setStatusInterval = () => {
    this.checkStatusInterval = setInterval(() => {
      Object.keys(this.state.drones).forEach((drone, i) => {
        if (this.state.drones[drone].lastActive) {
          let currentTimestamp = new Date();
          let droneLastActive = new Date(this.state.drones[drone].lastActive);
          if (((currentTimestamp - droneLastActive) / 1000) <= 60) {
            this.setState((prevState) => ({
              ...prevState,
              drones: {
                ...prevState.drones,
                [drone]: {
                  ...prevState.drones[drone],
                  online: true
                }
              }
            }))
          } else {
            this.setState((prevState) => ({
              ...prevState,
              drones: {
                ...prevState.drones,
                [drone]: {
                  ...prevState.drones[drone],
                  online: false
                }
              }
            }))
          }
        } else {
          this.setState((prevState) => ({
            ...prevState,
            drones: {
              ...prevState.drones,
              [drone]: {
                ...prevState.drones[drone],
                online: false
              }
            }
          }))
        }
      })
    }, 6000)
  }

  getPageData = () => {
    API.adminGetAllDronesStatusAPI(this.state.drone, this.state.selectedUser).then(async (allDrones) => {
      const drone = allDrones.drones.find(drone => drone.drone_id === this.state.drone);
      if (allDrones.drone?.length == 0) {
        window.location.replace(process.env.REACT_APP_CLIENT_PROTOCOL + "://" + process.env.REACT_APP_CLIENT_HOST + (process.env.REACT_APP_CLIENT_PORT ? process.env.REACT_APP_CLIENT_PORT : "") + "/admin/drone_control_center/all_drones");
      } else {
        if (drone) {
          this.setState({
            projectName: drone.project,
            planName: drone.plan,
          });

          let flightId = drone.flightID ? drone.flightID : drone.flight_id;
          this.getOldLogData(flightId)
          this.getFlightData(flightId)
          let droneObject = {
            ...drone,
            teleData: drone.teleData ? drone.teleData : {},
            status: { ...JSON.parse(drone.status), timestamp: new Date().getTime() },
            streamKey: drone.stream_key,
            streamURL: `${drone.uri ? `https://${drone.uri}` : process.env.REACT_APP_STREAMING_BASE}/streams/${drone.stream_key}.m3u8`,
            droneName: drone.drone_name,
          };
          this.setState({
            flightID: flightId,
            drones: { [drone.drone_id]: droneObject },
            streamURL: droneObject.streamURL,
          }, () => {
            if (!qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).drone || !Object.keys(this.state.drones).includes(this.state.drone)) {
              window.location.replace(`${process.env.REACT_APP_CLIENT_PROTOCOL}://${process.env.REACT_APP_CLIENT_HOST}${process.env.REACT_APP_CLIENT_PORT ? `:${process.env.REACT_APP_CLIENT_PORT}` : ""}/admin/drone_control_center/all_drones`);
            }
          });
        } else {
          window.location.replace(`${process.env.REACT_APP_CLIENT_PROTOCOL}://${process.env.REACT_APP_CLIENT_HOST}${process.env.REACT_APP_CLIENT_PORT ? `:${process.env.REACT_APP_CLIENT_PORT}` : ""}/admin/drone_control_center/all_drones`);
        }
      }
    });

  }

  getFlightData = async (flightId) => {
    try {
      const result = await API.adminGetFlightDetails(flightId)
      this.setState({
        flight: result.flight,
        loading: false,
      })
    }
    catch (e) {
      console.error("ERROR", e)
    }
  }

  getOldLogData = (flightId) => {
    return new Promise((resolve, reject) => {
      API.log(flightId, 'AS_TELE_DATA', 0, 0).then((logData) => {
        for (let i = 0; i < logData.logs.length; i++) {
          let log1 = JSON.parse(logData.logs[i].data)
          this.state.trailLogs.push({ lat: Number(log1.lat), lng: Number(log1.lon) })
        }
        resolve()
      })
    })
  }

  componentWillMount() {
    SetRestrictSesssionExpire(true)
    if (!this.props.pip) {
      document.title = "Drone Control Center - Fly View";
    }
    addUserAnalytic(this.props.location.pathname).then(id => { this.setState({ outId: id }) })
    if (qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).drone && qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).user) {
      this.setState({
        drones: { [qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).drone]: {} },
        drone: qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).drone,
        selectedUser:  qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).user,
      }, () => {
        this.getPageData();
        this.setStatusInterval()
      })
    } else {
      API.getUserType().then(([blah, type, user]) => {
        this.setState((state) => ({
          ...state,
          user,
          type,
          sidebarMode: "shrunk",
          selectedUser:  qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).user,
          drone: qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).drone
        }), () => {
          this.getPageData();
          this.setStatusInterval()
        })
      }, (e) => {
        window.location.replace("/login");
      })
    }
  }

  numberToPrecisionString = (n, p) => {
    if (n == undefined || n == null) {
      return "N/A";
    }
    let post = n.toString().split('.')[1];
    if (post) {
      return n.toString().split('.')[0] + "." + post.substring(0, p) + (((p - post.length) > 0) ? "0".repeat(p - post.length) : "");
    } else {
      return n.toString().split('.')[0] + "." + "0".repeat(p);
    }
  }

  render() {
    let drone = this.state.drone;
    return (
      <div className="wrapper" style={{ overflowY: "hidden" }} >
        <div className="right-content-new" style={{ height: this.props.height || "100vh", overflowY: "auto", left: "0px", width: this.props.width || "100vw" }} >

          <div className="top-bar-new">
            <div className="top-bar-text-new">{this.state.drones[drone] && this.state.flightID ? <div>
              Fly View - <span title={this.state.drones[drone]?.uin || this.state.drones[drone]?.drone_id}>{this.state.drones[drone]?.uin?.length > 20 ?
                this.state.drones[drone]?.uin.substring(0, 20) + '...' : this.state.drones[drone]?.uin || this.state.drones[drone]?.drone_name} | {this.state.flightID} </span> 
              <span title={this.state.drones[drone]?.user_name} style={{fontSize:'18px'}}> ({this.state.drones[drone]?.user_name.length > 25 ?
                this.state.drones[drone]?.user_name.substring(0, 25) + '...' : this.state.drones[drone]?.user_name})</span>
            </div>
              : "Drone Control Center - Fly View"}
            </div>
          </div>
          <div
            className="main-content main-content-dcc"
            style={{
              width: "100%",
              minHeight: "0px",
              padding: "0px",
            }}
          >
            {this.state.loading || this.state.drone == '' ? <LoaderComponent />
              : <>
                {this.state.drone && !this.state.flightReplayMode ?
                  <KafkaListener from={'DCCDroneFlyView'} id="dccFVKL" topics={[this.state.drones[this.state.drone].topic]} messageCallback={(topic, message) => {
                    if (topic.split('.').length == 3 && this.state.readyForListening) {
                      Object.keys(this.state.drones).forEach((drone, i) => {
                        if (this.state.drones[drone].topic == topic.split(".")[1]) {
                          this.setState((prevState) => ({
                            ...prevState,
                            drones: {
                              ...prevState.drones,
                              [drone]: {
                                ...this.state.drones[drone],
                                teleData: {
                                  lat: parseFloat(message.split(' ')[0]),
                                  lon: parseFloat(message.split(' ')[1])
                                }
                              }
                            }
                          }), async () => {
                            return
                          })
                        }
                      });
                    } else if (topic.split('.').length == 1 && this.state.readyForListening) {
                      Object.keys(this.state.drones).forEach((drone, i) => {
                        if (this.state.drones[drone].topic == topic) {
                          let parsedMessage = message.message;
                          switch (message.key) {
                            case "AS_TELE_DATA":
                              if (typeof parsedMessage.data.lat == "string") parsedMessage.data.lat = parseFloat(parsedMessage.data.lat);
                              if (typeof parsedMessage.data.lon == "string") parsedMessage.data.lon = parseFloat(parsedMessage.data.lon);
                              this.state.trailLogs.push({ lat: parsedMessage.data.lat, lng: parsedMessage.data.lon })
                              this.setState((prevState) => ({
                                ...prevState,
                                drones: {
                                  ...prevState.drones,
                                  [drone]: {
                                    ...this.state.drones[drone],
                                    teleData: parsedMessage.data,
                                    flightID: message.flightID ? message.flightID : prevState.drones[drone].flightID
                                  }
                                },
                                mapKey: "mapKeyN"
                              }), async () => {
                                return;
                              })
                              break;
                            case "AS_KEEP_ALIVE":
                              this.setState((prevState) => ({
                                ...prevState,
                                drones: {
                                  ...prevState.drones,
                                  [drone]: {
                                    ...this.state.drones[drone],
                                    lastActive: parseInt(message.timestamp) * 1000,
                                    teleData: {
                                      ...prevState.drones[drone].teleData,
                                      lat: parsedMessage.data && parsedMessage.data.lat ? parsedMessage.data.lat : (prevState.drones[drone].teleData ? prevState.drones[drone].teleData.lat : null),
                                      lon: parsedMessage.data && parsedMessage.data.lon ? parsedMessage.data.lon : (prevState.drones[drone].teleData ? prevState.drones[drone].teleData.lon : null)
                                    },
                                    flightID: message.flightID ? message.flightID : prevState.drones[drone].flightID
                                  }
                                }
                              }))
                              break;
                            case "AS_FLIGHT_LOG":
                              if (this.state.drones[drone].status.timestamp != (new Date(message.timestamp * 1000)).toLocaleString() && this.state.drones[drone].status.message != parsedMessage.data.message) {
                                this.setState((prevState) => ({
                                  ...prevState,
                                  drones: {
                                    ...prevState.drones,
                                    [drone]: {
                                      ...this.state.drones[drone],
                                      prevStatus: {
                                        ...prevState.drones[drone].status,
                                      },
                                      status: {
                                        ...parsedMessage.data,
                                        timestamp: message.timestamp * 1000 || new Date().getTime()
                                      }
                                    }
                                  }
                                }));
                              }
                              break;
                            case "AS_PLAN_RCVD_ACK":
                              if (parsedMessage.data && parsedMessage.data.project && parsedMessage.data.plan) {
                                this.setState({
                                  newPlan: parsedMessage.data
                                })
                              }
                              break;
                            default:
                              break;
                          }
                        }
                      });
                    }
                  }} /> : <div />}

                {!this.state.loading ?
                  <div style={{ height: this.props.pip ? 'calc(100% - 50px)' : '100%', width: '100%' }}>
                    <Map
                      replayPlayback={this.state.flightReplayMode}
                      replaySpeed={this.state.replaySpeed}
                      showIcons={!this.props.pip}
                      key={this.state.mapKey + "-mapComp"}
                      className="dcc-all-drones-map"
                      drones={this.state.flightReplayMode ? {
                        [this.state.flight.drone_id]: this.state.drones[this.state.drone]
                      } : {
                        [this.state.drone]: this.state.drones[this.state.drone]
                      }}
                      fenceBoundary={this.state.flight?.fence_boundary}
                      planBoundary={this.state.flight?.plan_boundary}
                      trailLogs={this.state.trailLogs}
                      componentType='DCCflyView'
                      projectName={this.state.projectName}
                      planName={this.state.planName}
                      user={this.state.user}
                      setBaseLayerToState={this.setBaseLayerToState}
                      markerClickCallback={() => { }}
                      flightReplayMode={this.state.flightReplayMode}
                      replayDroneID={this.state.flightReplayMode ? this.state.flight.drone_id : undefined}
                      replayTeleData={this.state.flightReplayMode ? this.state.drones[this.state.flight.drone_id].teleData : undefined}
                    />
                  </div>
                  : <div />}

                <div className="flyViewStats" style={{ bottom: this.state.flightReplayMode ? "70px" : "20px", }}>

                  <div className="statusTextData">
                    <div className="dcc-fly-view-status2" >
                      {getStatusElement(this.state.drones[this.state.drone].prevStatus, "white", 'prevStatus')}
                    </div>
                    <div className="dcc-fly-view-status1" >
                      {getStatusElement(this.state.drones[this.state.drone].status, undefined, 'current')}
                    </div>
                    {this.state.flightReplayMode && <div className="dcc-fly-view-status2" >
                      {getStatusElement(this.state.drones[this.state.drone].nextStatus, "white", 'nextStatus')}
                    </div>}
                  </div>

                  <div style={{ display: 'flex', marginTop: '10px' }}>
                    <div className="dcc-fly-view-status-data" style={{ gridTemplateColumns: "auto auto auto auto" }}>
                      <div className="dcc-fly-view-status-sub" >
                        <img src={LocationIcon} />
                        <div>
                          <p>{this.numberToPrecisionString(this.state.drones[this.state.drone].teleData.lat, 6)}°</p>
                          <p>{this.numberToPrecisionString(this.state.drones[this.state.drone].teleData.lon, 6)}°</p>
                        </div>
                      </div>
                      <div className="dcc-fly-view-status-sub">
                        <img src={DirectionIcon} />
                        <div>
                          <p>{(this.state.drones[this.state.drone].teleData.dir ? headingToDirection(this.state.drones[this.state.drone].teleData.dir) : "N/A")}</p>
                          <p>{(this.state.drones[this.state.drone].teleData.dir ? this.state.drones[this.state.drone].teleData.dir : "N/A")}°</p>
                        </div>
                      </div>
                      <div className="dcc-fly-view-status-sub">
                        <img src={SatellitesIcon} />
                        <div>
                          <p>{(this.state.drones[this.state.drone].teleData.sat ? this.state.drones[this.state.drone].teleData.sat : "N/A") + " sats"}</p>
                        </div>
                      </div>
                      <div className="dcc-fly-view-status-sub">
                        <img src={AltitudeIcon} />
                        <div>
                          <p>{(this.state.drones[this.state.drone].teleData.alt ? this.numberToPrecisionString(this.state.drones[this.state.drone].teleData.alt, 2) : "N/A") + " m"}</p>
                        </div>
                      </div>

                      <div className="dcc-fly-view-status-sub">
                        <img src={VelocityIcon} />
                        <div>
                          <p>{this.state.drones[this.state.drone].teleData.vel ? this.numberToPrecisionString(this.state.drones[this.state.drone].teleData.vel, 5) : "N/A"}</p>
                        </div>
                      </div>
                      <div className="dcc-fly-view-status-sub">
                        <img src={BatteryIcon} />
                        <div>
                          <p>{(this.state.drones[this.state.drone].teleData.bat && this.state.drones[this.state.drone].teleData.bat != "65535" ? this.numberToPrecisionString(this.state.drones[this.state.drone].teleData.bat, 2) : "0") + " V"}</p>
                        </div>
                      </div>
                      <div className="dcc-fly-view-status-sub">
                        <img src={ModeIcon} />
                        <div>
                          <p>{this.state.drones[this.state.drone].teleData.mod ? convertMode(this.state.drones[this.state.drone].teleData.mod) : "N/A"}</p>
                        </div>
                      </div>
                      <div className="dcc-fly-view-status-sub">
                        <img src={AttitudeIcon} />
                        <div>
                          <p>{"R: " + this.numberToPrecisionString(this.state.drones[this.state.drone].teleData.rol, 4)}</p>
                          <p>{"P: " + this.numberToPrecisionString(this.state.drones[this.state.drone].teleData.pit, 4)}</p>
                          <p>{"Y: " + this.numberToPrecisionString(this.state.drones[this.state.drone].teleData.yaw, 4)}</p>
                        </div>
                      </div>
                    </div>


                    {this.state.drones[this.state.drone].teleData.liquidConsumed || this.state.drones[this.state.drone].teleData.remainingLiquidPercentage || this.state.drones[this.state.drone].teleData.flowRate ||
                      this.state.drones[this.state.drone].teleData.sprayedArea || this.state.drones[this.state.drone].teleData.sprayedDistance || this.state.drones[this.state.drone].teleData.sprayPumpRate || this.state.drones[this.state.drone].teleData.liveObstacleDistance ?
                      <div className="dcc-fly-view-status-data">
                        <div className="dcc-fly-view-status-sub" style={{ width: '150px' }}>
                          <img src={waterDrop} />
                          <div>
                            <p>Consumed</p>
                            <p>{(this.state.drones[this.state.drone].teleData.liquidConsumed ? this.state.drones[this.state.drone].teleData.liquidConsumed : "N/A")} ml</p>
                          </div>
                        </div>
                        <div className="dcc-fly-view-status-sub" style={{ width: '150px' }}>
                          <img src={acreIcon} />
                          <div>
                            <p>Area Covered</p>
                            <p>{(this.state.drones[this.state.drone].teleData.sprayedArea ? this.state.drones[this.state.drone].teleData.sprayedArea : "N/A")} Acre</p>
                          </div>
                        </div>
                        <div className="dcc-fly-view-status-sub" style={{ width: '150px' }}>
                          <img src={distanceIcon} />
                          <div>
                            <p>Distance Travelled</p>
                            <p>{(this.state.drones[this.state.drone].teleData.sprayedDistance ? this.state.drones[this.state.drone].teleData.sprayedDistance : "N/A")} m</p>
                          </div>
                        </div>
                        <div className="dcc-fly-view-status-sub" style={{ width: '150px' }}>
                          <img src={windIcon} />
                          <div>
                            <p>Pump Rate</p>
                            <p>{this.state.drones[this.state.drone].teleData.sprayPumpRate ? this.state.drones[this.state.drone].teleData.sprayPumpRate : "N/A"} %</p>
                          </div>
                        </div>
                        <div className="dcc-fly-view-status-sub" style={{ width: '150px' }}>
                          <img src={wave} />
                          <div>
                            <p>Flow Rate</p>
                            <p>{this.state.drones[this.state.drone].teleData.flowRate ? this.state.drones[this.state.drone].teleData.flowRate : "N/A"} L/min</p>
                          </div>
                        </div>
                        <div className="dcc-fly-view-status-sub" style={{ width: '150px' }}>
                          <img src={locDistance} />
                          <div>
                            <p>Obs Alt</p>
                            <p>{this.state.drones[this.state.drone].teleData.liveObstacleDistance ? this.state.drones[this.state.drone].teleData.liveObstacleDistance : "N/A"} cm</p>
                          </div>
                        </div>
                      </div>
                      : <></>}
                  </div>
                </div>
              </>}
          </div>
        </div>
      </div >

    );
  }
}

const mapStateToProps = state => {
  return {
    activeTeamId: state.profile.activeTeamId,
    activeTeam: state.profile.activeTeam,
    SubscriptionData: state.subscription.subscriptionData,
    activesubscription: state.activesubscription.activesubscription,
    isLoggedInUserOwner: state.profile.isLoggedInUserOwner
  };
};

export default (connect(mapStateToProps)(AdminMainPageComponent));

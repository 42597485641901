import React, { useEffect, useState } from 'react'
import { AnimatedModal } from '../../ReusableComponents/AnimatedModal'
import * as API from "../../api"
import AppConstants from '../../AppConstants'
import ButtonWithIcon from '../../ReusableComponents/ButtonWithIcon'
import { Popup, displayRazorpay } from '../../ReusableComponents/reusableFunctions'
import { useHistory } from 'react-router-dom'
import AeroGCSLogo from "../../../src/icons/AeroGCSEnterprise.svg"

const RenewSubscription = (props) => {
    const history = useHistory()

    const handleCheckout = async () => {
        Popup.alert("WAIT", "Redirecting to payment, please wait...")
        try {
            const data = await API.createOrderForRenew((Number(props.data.price) * Number(props.data.units)), props.data);
            if (data.success) {
                Popup.close()
                displayRazorpay({
                    key: process.env.REACT_APP_RAZORPAY_KEY,
                    amount: data.order.amount,
                    currency: "INR",
                    name: "AeroGCS",
                    image: AeroGCSLogo,
                    order_id: data.order.id,
                    handler: async (response) => {
                        const res = await API.verifyRazorPayPayment(response)
                        if (res.success) {
                            history.push(`/payment/success?reference=${res.payment_id}`, {
                                message: "Congratulations, your subscription will be activated in few minutes..."
                            })
                        } else {
                            history.push(`/payment/failed?reference=${res.payment_id}`, {
                                message: "Oops, payment failed die to some reason!"
                            })
                        }
                    },
                    notes: {
                        payment_type: "plan_subscription_Renew",
                        db_subscription_plan_id: props.data.aeroGCS_subscription_id,
                        quantity: props.data.units,
                    },
                    prefill: {
                        email: props.user.username,
                    },
                    readonly: { email: true },
                    theme: {
                        "color": "#2989cf"
                    },
                    method: {
                        upi: false, 
                    },
                })
            }
        } catch (e) {
            Popup.alert("ERROR", e.message)
        }
    }

    return <>
        {/* <ButtonWithIcon isBtnActive height={"30px"} padding={"6px 20px 10px"} text="Pay Now" onClick={handleCheckout} /> */}
        <div style={{ color: '#007BFF', cursor: 'pointer', fontSize: '14px', textDecoration: 'underline' }} onClick={handleCheckout}>Pay Now</div>
    </>
}

export default RenewSubscription
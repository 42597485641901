import React, { Component } from "react";
import * as API from "../api.js";
import "../style.css";
import Map from "../MapComponent.js";
import VideoPlayer from "../VideoPlayer.js"
import allFeedsButton from '../icons/allFeedsButton.png';
import max from '../icons/liveFeedmax.png'
import { addUserAnalytic, checkSubscriptionAndStorage, updateOutTime } from '../ReusableComponents/reusableFunctions'
import { getActiveTeam } from '../Teams/index.js';
import SubscriptionExpireModal from "../subscription/Reusable/SubscriptionExpireModal.js";
import AppConstants from "../AppConstants.js";
import LoaderComponent from "../ReusableComponents/LoaderComponent.js";
import DroneWithBg from "../icons/DroneWithBg.svg"
import Select from "react-dropdown-select";

class AdminDCCAllDrones extends Component {
    state = {
        user: {},
        type: "",
        loading: true,
        recentReplaySidebar: true,
        dronesLoading: true,
        title: "ALL",
        drones: {},
        mini: false,
        outId: "",
        showSubscriptionExpirePopup: false,
        selectedUser: [],
        usersData: [],
        mapLoading: true,
    };

    changeDrone = (drone) => {
        console.log(drone);
        window.open(`/admin/drone_control_center/fly_view?drone=${drone.drone_id}&user=${drone.userid}`, '_blank');
    }
    componentWillUnmount() {
        clearInterval(this.interval)
        clearInterval(this.checkStatusInterval)
    }

    componentWillMount() {
        document.title = "Admin Drone Control Center";

        if (this.props && this.props.location && this.props.location.state) {
            this.setState((prevState) => ({
                ...prevState,
                user: this.props.location.state.user,
                type: this.props.location.state.type,
                sidebarMode: this.props.location.state.sidebarMode,
            }), () => {
                this.getPageData();
                this.getAllUsers()
                this.setStatusInterval();
            });
        } else {
            API.getUserType().then(([blah, type, user]) => {

                this.setState((state) => ({
                    ...state,
                    user,
                    type,
                    sidebarMode: "shrunk"
                }), () => {
                    this.getPageData();
                    this.getAllUsers()
                    this.setStatusInterval();
                })
            }, (e) => {
                window.location.replace("/login");
            })
        }

    }

    setStatusInterval = () => {
        this.checkStatusInterval = setInterval(() => {
            this.getPageData()
        }, 60000)
    }

    getAllUsers = async () => {
        try {
            const usersData = await API.users(" ", 0, 2000000, 'username', 'ASC', 'active', null, null)
            this.setState({ usersData: usersData.users || [] })
        }
        catch (e) {
            console.log(e);
        }
    }

    getPageData = () => {
        API.adminGetAllDronesStatusAPI(undefined, this.state.selectedUser.length > 0 ? this.state.selectedUser[0].value.userid : undefined).then(async allDrones => {
            let allDronesObject = {};
            await allDrones.drones.forEach(drone => {
                allDronesObject[drone.id] = {
                    ...drone,
                    droneName: drone.drone_name,
                    streamURL: `${drone?.uri && drone?.stream_key ? `https://${drone.uri}/streams/${drone?.stream_key}.m3u8` : ''}`,
                }
            })
            this.setState({ dronesLoading: false, drones: allDronesObject, loading: false, mapLoading: false })

        }).catch(error => {
            try {
                console.log(JSON.stringify(error));
            } catch (e) {
                console.log(error);
            }
        })
    }



    render() {
        let { user, type, sidebarMode, recentReplaySidebar } = this.state;
        let isDroneLive = Object.keys(this.state.drones).filter((drone) => { return this.state.drones[drone].online }).length > 0
        return (
            <div className="wrapper"
                style={{ overflowY: "hidden" }} >
                <div
                    className="right-content-new"
                    style={{ height: "100vh", overflowY: "auto", left: "0px", width: "100vw" }}
                >
                    <div className="top-bar-new">
                        <div className="top-bar-text-new">Drone Control Center - All Available Drones</div>
                    </div>
                    {this.state.loading ? <LoaderComponent /> :
                        <div className="main-content main-content-dcc" style={{ paddingTop: "1px", paddingLeft: "0px", paddingRight: "0px", display: "flex", }}>
                            {this.state.mapLoading ? <LoaderComponent />
                                : <Map
                                    isAdmin={true}
                                    className="dcc-all-drones-map"
                                    drones={this.state.drones}
                                    showIcons={true}
                                    includePortsInRecenter={true}
                                    recenterMap={true}
                                    control={true}
                                    markerClickCallback={(drone, userid) => {
                                        let data = this.state.drones[drone].drone_id
                                        window.open(`/admin/drone_control_center/fly_view?drone=${data}&user=${userid}`, '_blank');
                                    }}
                                />}
                            <div className="droneLiveCountNumber" style={{ backgroundColor: isDroneLive ? '#238E28' : '#868F9C' }}>{
                                Object.keys(this.state.drones).filter((drone) => { return this.state.drones[drone].online }).length}</div>
                            <img src={DroneWithBg} className="droneLiveCount" />
                            <div className="droneLiveSelect">
                                {this.state.selectedUser[0]?.label.length > 32 && this.state.selectedUser[0]?.label}
                                <Select
                                    placeholder="Select User"
                                    options={this.state.usersData?.map(u => ({
                                        label: u.username || '',
                                        value: u || ''
                                    }))}
                                    searchable={true}
                                    values={this.state.selectedUser}
                                    className="custom_dropdown"
                                    onChange={(values) => {
                                        this.setState({ selectedUser: values, mapLoading: true }, () => { this.getPageData() })
                                    }}
                                />
                            </div>
                            <div className="droneLiveUserSelect">
                                <Select
                                    placeholder="Select Drone"
                                    searchable={true}
                                    noDataRenderer={() => {
                                        return <span style={{ textAlign: 'center', padding: '5px 0px' }}>Drones not available</span>
                                    }}
                                    options={
                                        Object.keys(this.state.drones).filter((drone) => {
                                            if (this.state.drones[drone].id && this.state.drones[drone].online == true) {
                                                return true
                                            } else {
                                                return false
                                            }
                                        }).map((drone, i) => ({
                                            label: `${this.state.drones[drone].uin} (${this.state.drones[drone].drone_name})`,
                                            value: drone
                                        }))
                                    }
                                    className="custom_dropdown"
                                    onChange={(e) => {
                                        if (e.length > 0) {
                                            this.changeDrone(this.state.drones[e[0]?.value])
                                        }

                                    }}
                                />
                            </div>
                        </div>
                    }
                </div>
            </div>
        );
    }
}

export default AdminDCCAllDrones
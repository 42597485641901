import React, { forwardRef, Component, createRef, useRef } from "react";
import * as API from "./api.js";
import "./style.css";
import ProjectsInActive from './icons/ProjectsInactive.png';
import AeroGCSInactive from './sidebaricon/AeroGCSInactive.png';
import DronesActive from "./dronesActive.png";
import RPAS from "./rpas.png";
import MenuDark from './icons/MenuDark.png';
import { Redirect, withRouter } from "react-router-dom";
import { ActivityPopup } from './ReusableComponents/ActivityPopup.js';
import TableView from "./ReusableComponents/TableView/TableView.js";
import { getFlightTime } from "./AeroGCSEnterprise/Flights/Flights.js";
import { BreadCrumbs } from "./ReusableComponents/BreadCrumbs.js";
import dateFormat from 'dateformat';
import Button from "./ReusableComponents/Button/Button.js";
import { GCS_FEATURES, getActiveTeam, getFeaturesPermissions } from "./Teams/index.js";
import { Popup, addUserAnalytic, getWeeksInMonth, updateOutTime } from "./ReusableComponents/reusableFunctions.js";
import { AnimatedModal } from './ReusableComponents/AnimatedModal.js';
import flightReplay from "./replayVideo.svg";
import lockIcon from './userAuth/assets/lock.svg'
import eyeIcon from './userAuth/assets/eye.svg'
import eyeOffIcon from './userAuth/assets/eye-off.svg'
import closeBtn from "./icons/close.png"
import DroneErrorLogsBtn from "./ReusableComponents/ErrorLogsBtn.js";
import LoaderComponent from "./ReusableComponents/LoaderComponent.js";
import ReactDatePicker from "react-datepicker";
import WeekTile from "./AeroGCSEnterprise/WeeklyReport/WeekTile.js";
import { toDate } from "date-fns";
import MultipleMonthPicker from "./MultipleMonthPicker.js";
import WeekPicker from "./WeekPicker.js";
import Cookies from "universal-cookie";
const cookies = new Cookies();

let otpInterval = null

class WeeklyDrnHealthReport extends Component {

    state = {
        user: {},
        type: "",
        deleteRedirect: false,
        drone: {},
        model: {},
        sidebarMode: this.props.location.state
            ? this.props.location.state.sidebarMode
            : "shrunk",
        showUnregisterDronePopup: false,
        addLeaseDronePopUp: false,
        usersCustomers: [],
        selectedCustomer: '',
        selectedCustomerName: '',
        lease: [],
        edit: false,
        editLease: '',
        lease_drone: false,
        leaseActivityPopup: false,
        droneRentEdit: false,
        rentAmount: 0,
        isLoading: true,
        activityPopup: undefined,
        pageSize: 15,
        pageNumber: 1,
        permissions: undefined,
        deleteData: undefined,
        outId: "",
        activityPopup: undefined,
        deleteFlightLogs: false,
        droneDataDeleteOtpPopup: false,
        errorOtp: '',
        showOtp: false,
        otpTimer: ``,
        timeOutFlag: false,
        verifyOtpAndDeleteData: false,
        otp: '',
        droneDelete: false,
        loading: true,
        occuredError: [],
        medianErrorCount: 0,
        hardwareHealthData: {},
        selectedMonthDate: new Date(),
        selectedMonth: new Date().getMonth(),
        weeksInMonth: [],
        selectWeekPopup: false,
        selectedTab: "Week",
        flights: [],
        buttonOptions: [
            { id: 1, placeholder: "Week", },
            { id: 2, placeholder: "Month", },
        ],
        dateRange: {
            frmDate: new Date(new Date()).setDate(new Date().getDate() - 6),//  new Date(2016, 0, 1).setHours(0, 0, 0, 1), 
            toDate: new Date().setHours(23, 59, 59, 999)
        },
        showFilter: false
    };
    parentSidebarSwitch = () => {
        this.setState((state) => ({
            ...state,
            sidebarMode: state.sidebarMode == "shrunk" ? "expanded" : "shrunk",
        }));
    };

    closeActivityPopup = () => {
        this.setState((state) => ({
            ...state,
            activityPopup: undefined,
            deleteData: undefined,
            deleteFlightLogs: false,
            errorOtp: '',
            showOtp: false,
            otpTimer: ``,
            timeOutFlag: false,
            verifyOtpAndDeleteData: false,
            otp: '',
            otpTimer: '',
            droneDelete: false,
        }))
        if (this.state.deleteRedirect) window.history.back();
    }

    unregister = () => {
        this.setState((state) => ({
            activityPopup: {
                open: true,
                msg: "Unregistering Drone , Please Wait...",
                item: "WAIT",
                icon: "WAIT",
            }
        }), () => {
            API.removeGCSDrones(this.state.drone.drone_id)
                .then((results) => {
                    console.log("result:", results);
                    this.setState((prevState) => ({
                        ...prevState,
                        activityPopup: {
                            open: true,
                            msg: "Drone unregistered successfully.",
                            item: "COMPLETE",
                            icon: "COMPLETE",
                        }
                    }), () => {
                        this.getDronesData()
                    });
                })
                .catch((e) => {
                    this.setState((state) => ({
                        activityPopup: {
                            open: true,
                            msg: e.message || 'Error',
                            item: "ERROR",
                            icon: "ERROR",
                        }
                    }))
                    try {
                        console.log(JSON.stringify(e));
                    } catch (exception) {
                        console.log(e);
                    }
                });
        })

    };

    getFlightStats = () => {
        try {
            return new Promise(async (resolve, reject) => {
                let { frmDate, toDate } = this.state.dateRange;
                API.getGCSFlightStats({ drones: [this.state.drone.drone_id], fromDateTime: new Date(frmDate).toISOString(), toDateTime: new Date(toDate).toISOString(), })
                    .then((flightsStats) => {
                        this.setState({
                            totalFlights: flightsStats.total_flights,
                            totalAcreage: flightsStats.total_acres_covered,
                            totalDistance: flightsStats.total_distance,
                            flightsStats,
                            loading: false,
                            isLoading: false,
                        })
                    })
            })
        } catch (err) {
            console.log("err : ", err);
        }
    }

    componentWillUnmount() { updateOutTime(this.state.outId) }

    componentWillMount() {
        getActiveTeam().then(activeTeam => {
            this.setState({
                activeTeam: activeTeam
            }, () => {
                if (this.props.location.state.drone) {
                    document.title =
                        this.props.location.state.drone.drone_id +
                        " - Drone Details - Management Services";
                    let { user, type, drone } = this.props.location.state;
                    getFeaturesPermissions([GCS_FEATURES.PLANS]).then(permissions => {
                        addUserAnalytic(this.props.location.pathname).then(id => { this.setState({ outId: id }) })
                        let cookieData = cookies.get("Drone_Health_Report");
                        this.setState({
                            user,
                            type,
                            drone,
                            permissions,
                            isLoading: true,
                            ...cookieData
                        }, async () => {
                            let { frmDate, toDate } = this.state.dateRange;
                            await this.getFlightsData()
                            await this.getErrorLogData(new Date(frmDate).toISOString(), new Date(toDate).toISOString())
                            // await this.getHardWareHealth()
                            await this.getFlightStats()
                        });
                    })
                } else {
                    addUserAnalytic(this.props.location.pathname).then(id => { this.setState({ outId: id }) })
                    window.location.replace(
                        process.env.REACT_APP_CLIENT_PROTOCOL +
                        "://" +
                        process.env.REACT_APP_CLIENT_HOST +
                        (process.env.REACT_APP_CLIENT_PORT
                            ? process.env.REACT_APP_CLIENT_PORT
                            : "") +
                        "/management_services/dashboard"
                    );
                    return;
                }
            })
        })
    }

    getDronesData = async () => {
        try {
            this.setState({
                isLoading: true
            }, async () => {
                const result = await API.gcsDrones(this.state.drone.id)
                result.drones.map((drone1, key) => {
                    if (drone1.drone_id == this.state.drone.drone_id) {
                        this.setState(state => ({
                            ...state,
                            drone: drone1,
                            isLoading: false
                        }))
                    }
                })
            })
        }
        catch (e) {
            console.error(e)
            window.alert(e.message || "Something went wrong while getting drone data.")
        }
    }

    getFlightsData = () => {
        try {
            return new Promise(async (resolve, reject) => {
                let cookieData = cookies.get("Drone_Health_Report");
                this.setState({
                    ...this.state,
                    ...cookieData
                }, async () => {
                    let { frmDate, toDate } = this.state.dateRange;
                    const flights = await API.getGCSFlights({ drones: [this.state.drone.drone_id], fromDateTime: new Date(frmDate).toISOString(), toDateTime: new Date(toDate).toISOString(), orderBy: undefined, orderType: undefined, pageSize: this.state.pageSize, pageNumber: (this.state.pageNumber), projectName: undefined, planName: undefined, weekly: true })
                    this.setState({ flights }, () => { resolve() })
                })
            })
        }
        catch (e) {
            console.error(e)
            window.alert(e.message || "Something went wrong while getting flights data.")
        }
    }

    getErrorLogData = (fromDate, toDate) => {
        try {
            return new Promise(async (resolve, reject) => {
                let droneId = this.props.location.state.drone.drone_id
                const res = await API.getErrorLogs(droneId, fromDate, toDate)
                let occuredError = []
                let min;
                let max;
                if (res.logs.length != 0) res.logs[0].Errors.map(d => {
                    // if(d.Categories = )
                    if (d.Occurrences && d.Occurrences >= 1) {
                        occuredError.push(d)
                        if (min) {
                            if (d.Occurrences <= min) {
                                if (max) {
                                    if (d.Occurrences >= max) max = Number(d.Occurrences)
                                } else max = min
                                min = Number(d.Occurrences)
                            }
                            else if (max) {
                                if (d.Occurrences >= max) max = Number(d.Occurrences)
                            } else max = Number(d.Occurrences)
                        } else min = Number(d.Occurrences)
                    }
                });

                this.setState({
                    occuredError,
                    medianErrorCount: (min + max) / 2
                }, () => { resolve() })
            })
        }
        catch (e) {
            console.error(e)
            window.alert(e.message || "Something went wrong while getting flights data.")
        }
    }

    getHardWareHealth = () => {
        try {
            return new Promise(async (resolve, reject) => {
                let droneId = this.props.location.state.drone.drone_id
                const res = await API.getHardWareHealthData(droneId)
                this.setState({
                    hardwareHealthData: res.hardwareHealthData
                }, () => { resolve() })
            })
        }
        catch (e) {
            console.error(e)
            window.alert(e.message || "Something went wrong while getting flights data.")
        }
    }

    deleteFlight = async () => {
        try {
            this.setState({
                activityPopup: {
                    open: true,
                    msg: 'Deleting flight and related data please wait...',
                    item: "WAIT",
                    icon: "WAIT",
                }
            })
            const data = await API.deleteFlightData(this.state.deleteData.id)
            this.setState({
                deleteData: undefined,
                activityPopup: {
                    open: true,
                    msg: data.message,
                    item: "COMPLETE",
                    icon: "COMPLETE",
                }
            })
            this.getDronesData()
            this.getFlightsData()
            let { frmDate } = this.state.dateRange;
            const now = new Date();
            const toDate = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), 23, 59, 59, 999));
            const flightsStats = await API.getGCSFlightStats({ drones: [this.state.drone.drone_id], fromDateTime: new Date(frmDate).toISOString(), toDateTime: new Date(toDate).toISOString(), })
            this.setState({
                totalFlights: flightsStats.total_flights,
                flightsStats
            })
        }
        catch (e) {
            this.closeActivityPopup()
            Popup.alert("ERROR", e?.message || e)
        }
    }


    deleteDrone = () => {
        this.setState({
            activityPopup: {
                open: true,
                msg: "Deleting drone please wait...",
                item: "WAIT",
                icon: "WAIT",
            }
        }, () => {
            API.deleteDrone(this.state.drone.drone_id).then(async (result) => {
                let res = await result.json()
                if (result.status == 200) {
                    this.setState({
                        activityPopup: {
                            open: true,
                            msg: res.message,
                            item: "COMPLETE",
                            icon: "COMPLETE",
                        }
                    }, () => {
                        setTimeout(() => {
                            window.history.back()
                        }, 1000)
                    })
                } else if (result.status == 401) {
                    this.setState({
                        deleteFlightLogs: true,
                        activityPopup: {
                            open: true,
                            msg: `This drone has ${res.flightCount} associated flights. Are you sure you want to delete them as well?`,
                            item: "WARNING",
                            icon: "WARNING",
                        }
                    })
                } else {
                    this.setState({
                        activityPopup: {
                            open: true,
                            msg: res.message || 'Error',
                            item: "ERROR",
                            icon: "ERROR",
                        }
                    })
                }
            }).catch((e) => {
                this.setState({
                    activityPopup: {
                        open: true,
                        msg: e.message || 'Error',
                        item: "ERROR",
                        icon: "ERROR",
                    }
                })
            })
        })
    }

    sendOtpForDeleteLogs = () => {
        API.sendOtpForDeleteLogs(this.state.drone.drone_id).then(async (result) => {
            let res = await result.json()
            if (otpInterval) clearInterval(otpInterval);
            if (result.status == 200) {
                let timerData = res.remainTime
                let minutes = Number(timerData.split(':')[0])
                let seconds = Number(timerData.split(':')[1])
                console.log('time', minutes, seconds);
                otpInterval = setInterval(() => {
                    let min = `0${minutes}`
                    let sec = seconds < 10 ? `0${seconds}` : seconds
                    this.setState((state) => ({
                        ...state,
                        otpTimer: min < 1 ? `Resend OTP in ${min} : ${sec} sec` : `Resend OTP in ${min} : ${sec} min`
                    }))
                    seconds--;
                    if (seconds < 0) {
                        minutes--;
                        seconds = 59;
                        if (minutes < 0) {
                            clearInterval(otpInterval);
                            this.setState((state) => ({
                                ...state,
                                otpTimer: ``,
                                timeOutFlag: true
                            }))
                        }
                    }
                }, 1000);
                this.setState({ activityPopup: undefined, droneDataDeleteOtpPopup: true, timeOutFlag: false, resend: false, deleteFlightLogs: false })
            } else {
                this.setState({
                    activityPopup: {
                        open: true,
                        msg: res.message || 'Error',
                        item: "ERROR",
                        icon: "ERROR",
                    }
                })
            }
        }).catch((e) => {
            this.setState({
                activityPopup: {
                    open: true,
                    msg: e.message || 'Error',
                    item: "ERROR",
                    icon: "ERROR",
                }
            })
        })
    }

    verifyOtpAndDeleteDroneDataWithDrone = () => {
        this.setState({
            verifyOtpAndDeleteData: false,
            activityPopup: {
                open: true,
                msg: 'Deleting drone and all associated flights. please wait...',
                item: "WAIT",
                icon: "WAIT",
            }
        }, () => {
            API.verifyOtpAndDeleteDroneDataWithDrone(this.state.drone.drone_id, this.state.otp).then(async (result) => {
                let res = await result.json()
                if (result.status == 200) {
                    this.setState({
                        droneDataDeleteOtpPopup: false,
                        activityPopup: {
                            open: true,
                            msg: 'The drone and all associated flights have been successfully deleted.',
                            item: "COMPLETE",
                            icon: "COMPLETE",
                        }
                    }, () => {
                        setTimeout(() => {
                            window.history.back()
                        }, 1000)
                    })
                } else {
                    this.setState({
                        activityPopup: {
                            open: true,
                            msg: res.message || 'Error',
                            item: "ERROR",
                            icon: "ERROR",
                        }
                    })
                }
            }).catch((e) => {
                this.setState({
                    activityPopup: {
                        open: true,
                        msg: e.message || 'Error',
                        item: "ERROR",
                        icon: "ERROR",
                    }
                })
            })
        })
    }

    //(input, latestVersion)
    isFirmwareVersionUpdated(input, latestVersion) {
        // Regular expression to capture the version pattern "Vx.x.x"
        const versionPattern = /V(\d+\.\d+\.\d+)/;
        const match = input.match(versionPattern);

        if (match && match[1]) {
            // Extracted version from input
            const extractedVersion = match[1];
            // Check if extracted version matches the latest version
            return extractedVersion === latestVersion;
        } else {
            // No version found in input string
            return false;
        }
    }

    selectedMonth = async (e) => {
        this.setState({
            weeksInMonth: getWeeksInMonth(new Date(e).getMonth(), new Date(e).getFullYear(), this.state.user.created_on)
        }, () => { this.setState({ selectWeekPopup: true }) })
    }

    downloadHealthReportPDF = async () => {
        try {
            const frmDteToDte = this.state.filterFrmDateToDate;
            const flightStatData = this.state.flightsStats;
            const latestFlightData = this.state.flights?.[0] || {}
            const drndetails = this.state.drone;
            const errorLogs = this.state.occuredError

            let recomandationArray = [];

            // console.log("test :", latestFlightData.green_build_number == drndetails?.latestSoftwareVersions?.filter(v => v?.software_type == "GREEN")[0]?.version_number);


            if (this.state.flights.length != 0
                && (this.state.flights?.[0].green_build_number != drndetails?.latestSoftwareVersions?.filter(v => v?.software_type == "GREEN")[0]?.version_number
                    || !this.isFirmwareVersionUpdated(this.state?.flights?.[0].firmware_version_number, drndetails?.latestSoftwareVersions?.filter(v => v?.software_type == "FIRMWARE")[0]?.version_number))) {
                if (!(this.state.flights?.[0].green_build_number == drndetails?.latestSoftwareVersions?.filter(v => v?.software_type == "GREEN")[0]?.version_number)) {
                    recomandationArray.push(`Please Upgrade Software Version (${drndetails?.latestSoftwareVersions?.filter(v => v?.software_type == "GREEN")[0]?.version_number})`)
                }
                if (!(this.isFirmwareVersionUpdated(this.state?.flights?.[0].firmware_version_number, drndetails?.latestSoftwareVersions?.filter(v => v?.software_type == "FIRMWARE")[0]?.version_number))) {
                    recomandationArray.push(`Please Upgrade Software Version (${drndetails?.latestSoftwareVersions?.filter(v => v?.software_type == "FIRMWARE")[0]?.version_number})`)
                }
            }

            // if (latestFlightData.green_build_number == drndetails?.latestSoftwareVersions?.filter(v => v?.software_type == "GREEN")[0]?.version_number) {
            //     recomandationArray.push(`Please Upgrade Software Version (${drndetails?.latestSoftwareVersions?.filter(v => v?.software_type == "GREEN")[0]?.version_number})`)
            // }

            // if (this.isFirmwareVersionUpdated(latestFlightData.firmware_version_number, drndetails?.latestSoftwareVersions?.filter(v => v?.software_type == "FIRMWARE")[0]?.version_number)) {
            //     recomandationArray.push(`Please Upgrade Software Version (${drndetails?.latestSoftwareVersions?.filter(v => v?.software_type == "FIRMWARE")[0]?.version_number})`)
            // }

            let reconstruct = await JSON.stringify({
                "drone_detatils": {
                    "Basic_Info": {
                        "Drone_ID": drndetails.drone_id,
                        "Drone_Name": drndetails.drone_name,
                        "Drone UIN": drndetails.uin,
                        "Registered Date": dateFormat(drndetails.registered_date, "dd mmm yyyy"),
                        "Board Type": drndetails.board_type
                    },
                    "Flight_Summary": {
                        "Total_Number_of_Hours": getFlightTime(flightStatData.total_flight_time),
                        "Total_Number_of_flights": flightStatData.total_flights,
                        "Total_Acreage": `${(Number(flightStatData.total_acres_covered) * 0.000247105).toFixed(6)} acre`,
                        "Last_flight_date": dateFormat(drndetails.last_keep_alive, "dd mmm yyyy"),
                        "Total_Consumed_Liquid": `${this.state.flightsStats?.total_consumed_liquid} ml`
                    },
                    "Software_Information": {
                        "Software Version": latestFlightData.green_build_number,
                        "Status": latestFlightData.green_build_number === drndetails.latestSoftwareVersions?.filter(v => v?.software_type == "GREEN")[0]?.version_number ? 'Updated' : 'Not Updated',
                        "Android Version": drndetails.android_version
                    },
                    "Firmware_Information": {
                        "Firmware_Version": latestFlightData.firmware_version_number,
                        "Status": this.isFirmwareVersionUpdated(latestFlightData.firmware_version_number, drndetails?.latestSoftwareVersions?.filter(v => v?.software_type == "FIRMWARE")[0]?.version_number) ? 'Updated' : 'Not Updated'
                    },
                    "from_date_to_date": {
                        "from_date": dateFormat(this.state.dateRange.frmDate, "dd mmm yyyy"),
                        "to_date": dateFormat(this.state.dateRange.toDate, "dd mmm yyyy")
                    },
                    "Recommendation": recomandationArray
                },
                "error_logs": errorLogs
            });

            console.log("reconstruct : ", JSON.parse(reconstruct));

            let response = await API.downloadDroneHealthReport(reconstruct)
            console.log("response : ", response);
        } catch (err) {
            console.log("err : ", err);
        }
    }

    render() {
        let { user, type, model, drone, flights, sidebarMode } = this.state;
        let sidebarConfigurations = null;
        sidebarConfigurations = {
            options: [
                // {
                //   icon: service,
                //   text: "Services",
                //   path: "/"
                // },
                {
                    icon: AeroGCSInactive,
                    text: "AeroGCS",
                    path: "/"
                },
                {
                    icon: DronesActive,
                    text: "Drones",
                    path: "/drone_management/drones",
                    selected: true,
                },
                {
                    icon: RPAS,
                    text: "Drone Control Center",
                    path: "/drone_control_center/all_drones",
                },
                {
                    icon: ProjectsInActive,
                    text: "Projects",
                    path: "/projects",
                }
            ],
            user,
            type,
            mode: this.state.sidebarMode,
            aeroGCSHelp: true,
        };

        return user.username ? (
            <div className="wrapper">
                <div
                    className="right-content-new"
                    onClick={() => {
                        this.setState((state) => ({
                            ...state,
                            sidebarMode: "sdateRangehrunk",
                        }));
                    }}
                >
                    <AnimatedModal
                        isOpen={this.state.showFilter}
                        height="315px"
                        width="580px"
                    >
                        {this.state.selectedTab == 'Week' ?
                            <WeekPicker
                                fromDate={this.state?.user?.created_on}
                                onClose={() => { this.setState({ showFilter: false }) }}
                                onContinue={(dteRange) => {
                                    this.setState({
                                        // loading: true,
                                        isLoading: true,
                                        showFilter: false,
                                        dateRange: { frmDate: dteRange.frmDate, toDate: dteRange.toDate }
                                    }, async () => {
                                        cookies.set('Drone_Health_Report',
                                            {
                                                selectedTab: this.state.selectedTab,
                                                dateRange: this.state.dateRange,
                                                pageNumber: 0
                                            },
                                            { path: "/weekly_drone_health_report" })
                                        let { frmDate, toDate } = this.state.dateRange;
                                        await this.getFlightsData()
                                        await this.getErrorLogData(new Date(frmDate).toISOString(), new Date(toDate).toISOString())
                                        // await this.getHardWareHealth()
                                        await this.getFlightStats()
                                    })
                                }}
                            />
                            : <MultipleMonthPicker
                                fromDate={this.state?.user?.created_on}
                                onClose={() => { this.setState({ showFilter: false }) }}
                                onContinue={(dteRange) => {
                                    this.setState({
                                        isLoading: true,
                                        showFilter: false,
                                        dateRange: { frmDate: dteRange.frmDate, toDate: dteRange.toDate }
                                    }, async () => {
                                        cookies.set('Drone_Health_Report',
                                            {
                                                selectedTab: this.state.selectedTab,
                                                dateRange: this.state.dateRange,
                                                pageNumber: 0
                                            },
                                            { path: "/weekly_drone_health_report" })
                                        let { frmDate, toDate } = this.state.dateRange;
                                        await this.getFlightsData()
                                        await this.getErrorLogData(new Date(frmDate).toISOString(), new Date(toDate).toISOString())
                                        // await this.getHardWareHealth()
                                        await this.getFlightStats()
                                    })
                                }}
                            />}

                    </AnimatedModal>

                    <ActivityPopup
                        item={this.state.activityPopup?.item}
                        open={this.state.activityPopup?.open}
                        icon={this.state.activityPopup?.icon}
                        msg={this.state.activityPopup?.msg}
                        close={() => { this.closeActivityPopup() }}
                        onClickOk={() => {
                            if (this.state.deleteData) {
                                this.deleteFlight()
                            } else if (this.state.verifyOtpAndDeleteData) {
                                this.verifyOtpAndDeleteDroneDataWithDrone()
                            } else if (this.state.deleteFlightLogs) {
                                this.setState({
                                    activityPopup: {
                                        open: true,
                                        msg: "Sending OTP to delete flights associated with this drone. Please wait...",
                                        item: "WAIT",
                                        icon: "WAIT",
                                    }
                                }, () => {
                                    this.sendOtpForDeleteLogs()
                                })
                            } else if (this.state.drone.is_registered == false && this.state.droneDelete) {
                                this.deleteDrone()
                            } else {
                                this.unregister()
                            }
                        }}
                    />
                    <AnimatedModal
                        isOpen={this.state.droneDataDeleteOtpPopup}
                        height="350px"
                        width="500px"
                    >
                        <div style={{ width: '100%', textAlign: 'center', fontSize: '16px', fontWeight: '500', marginTop: '25px' }}>Verify Account</div>
                        <img src={closeBtn} style={{
                            width: "12px",
                            height: "12px",
                            cursor: "pointer",
                            top: "12px",
                            right: '17px',
                            position: 'absolute'
                        }} onClick={() => {
                            this.setState({
                                droneDataDeleteOtpPopup: false
                            })
                            this.closeActivityPopup()
                        }} />
                        <hr style={{ width: '96%', color: 'grey', marginTop: '10px' }} />
                        <div style={{ width: '100%', textAlign: 'center', lineHeight: '20px', marginTop: '10px', fontSize: '14px', fontWeight: '400', padding: '0px 20px' }}>An OTP to delete the drone-associated data has been sent to your registered email.</div>

                        <div class="login-error" id="otpErrorMsg" style={{ height: '30px', fontSize: '14px', color: 'red', marginTop: '5px' }}>{this.state.errorOtp}</div>

                        <div style={{ textAlign: 'center' }}>
                            <img style={{ position: 'absolute', marginTop: '21px', marginLeft: '25px' }} src={lockIcon} />
                            <input
                                autoFocus
                                class="text-input login-text-input" id="otp" style={{ width: '18rem' }} name="otp"
                                type={this.state.showOtp ? "text" : "password"} onChange={(e) => {
                                    this.setState({
                                        errorOtp: '',
                                        otp: e.target.value
                                    })
                                }}
                                placeholder="OTP" maxlength="6" required />
                            <img className="login-password-wrapper-img" id="view-password" style={{ cursor: 'pointer', top: '175px', left: '360px' }}
                                src={this.state.showOtp ? eyeOffIcon : eyeIcon} onClick={() => {
                                    this.setState({ showOtp: !this.state.showOtp })
                                }} />
                        </div>
                        <div style={{ height: '25px', fontSize: '14px', marginTop: '10px' }}>
                            {this.state.otpTimer}
                        </div>

                        <div style={{ textAlign: 'center', marginTop: '20px' }}>
                            {this.state.timeOutFlag === false ?
                                this.state.otp ?
                                    <button type="button" class="loginButton" name="otpButton" id="otpButton" onClick={() => {
                                        this.setState({
                                            verifyOtpAndDeleteData: true,
                                            deleteFlightLogs: false,
                                            activityPopup: {
                                                open: true,
                                                msg: 'Are you sure you want to delete this drone and all associated flights?',
                                                item: "WARNING",
                                                icon: "WARNING",
                                            }
                                        })
                                    }}>Verify</button>
                                    : <button type="button" class="loginButton" name="otpButton" id="otpButton" style={{ opacity: '0.5', cursor: 'not-allowed' }}>Verify</button>
                                : <button type="button" style={{ opacity: this.state.resend ? '0.5' : '1' }} class="loginButton" name="resendButton" id="resendButton" disabled={this.state.resend}
                                    onClick={() => {
                                        this.setState({ resend: true })
                                        this.sendOtpForDeleteLogs()
                                    }}>Resend OTP</button>}
                        </div>
                    </AnimatedModal>

                    <AnimatedModal
                        isOpen={this.state.selectWeekPopup}
                        height="350px"
                        width="650px"
                    >
                        <div style={{ width: '100%', textAlign: 'center', fontSize: '16px', fontWeight: '500', marginTop: '25px' }}>Select Week</div>
                        <img src={closeBtn} style={{
                            width: "12px",
                            height: "12px",
                            cursor: "pointer",
                            top: "12px",
                            right: '17px',
                            position: 'absolute'
                        }} onClick={() => {
                            this.setState({
                                selectWeekPopup: false
                            })
                        }} />
                        <hr style={{ width: '96%', color: 'grey', marginTop: '10px' }} />

                        <div className="weeks-grid" style={{ height: "265px", overflow: "auto" }}>
                            {this.state.weeksInMonth.map((week, weekIndex) => (
                                <WeekTile key={weekIndex}
                                    weekIndex={weekIndex}
                                    week={week}
                                    handleWeekClick={(e) => {
                                        this.setState({
                                            isLoading: true,
                                            selectedWeek: {
                                                frmDate: e.start.$d,
                                                toDate: e.end.$d
                                            },
                                            selectWeekPopup: false
                                        }, async () => {
                                            let { frmDate, toDate } = this.state.selectedWeek;
                                            await this.getFlightsData()
                                            await this.getErrorLogData(new Date(frmDate).toISOString(), new Date(toDate).toISOString())
                                            // await this.getHardWareHealth()
                                            await this.getFlightStats()
                                        })
                                    }}
                                />
                            ))}
                        </div>


                        <div style={{ textAlign: 'center', marginTop: '20px' }}>
                            action
                        </div>
                    </AnimatedModal>

                    <div className="top-bar-new">
                        <div className="top-bar-text-new">
                            <BreadCrumbs
                                data={[
                                    {
                                        displayName: "AeroGCS",
                                        pathname: "/",
                                        state: {
                                            user
                                        }
                                    },
                                    {
                                        displayName: 'Drones',
                                        pathname: "/drone_management/drones",
                                        state: {
                                            user
                                        }
                                    },
                                    {
                                        displayName: 'Drone Details',
                                        pathname: "/drone_management/drone_details",
                                        state: {
                                            user,
                                            drone: this.state.drone
                                        }
                                    },
                                    {
                                        displayName: 'Drone Health Report',
                                    }
                                ]} />
                        </div>
                    </div>
                    <div className="main-content-new">
                        {this.state.loading ? <LoaderComponent /> : <>
                            <div className="drone-details-card-view" style={{ marginTop: '15px' }}>
                                <div className="drone-details-card-view-heading">
                                    <div style={{ display: 'flex', gap: "10px", alignItems: 'center' }}>
                                        <div className="drone-details-card-view-heading-text" title={drone.drone_name || "NA"}>
                                            {drone.drone_name ? drone.drone_name : "NA"}
                                        </div>
                                    </div>
                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "10px" }}>
                                        <div className="duration_selectors">
                                            {this.state.buttonOptions.map((button, i) => (
                                                <button
                                                    style={{
                                                        borderLeft:
                                                            i === 0 &&
                                                            this.state.selectedTab != button.placeholder &&
                                                            "1px solid #C9C9C9",
                                                        borderRight:
                                                            this.state.selectedTab != button.placeholder &&
                                                            "1px solid #C9C9C9",
                                                    }}
                                                    className={
                                                        this.state.selectedTab === button.placeholder ? "active" : ""
                                                    }
                                                    onClick={() => { this.setState({ selectedTab: button.placeholder, showFilter: true }) }}
                                                >
                                                    {button.placeholder}
                                                </button>
                                            ))}
                                        </div>

                                        {/* <div onClick={() => {
                                            this.setState({
                                                showFilter: true
                                            })
                                        }}
                                            style={{
                                                border: "1px solid rgb(201, 201, 201)",
                                                width: "110px",
                                                height: "30px",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                borderRadius: "7px",
                                                fontSize: "14px",
                                                cursor: "pointer",
                                            }}
                                        >
                                            {this.state.selectedTab != 'Week' ? 'Select Month' : 'Select Week'}
                                        </div> */}

                                        <div>
                                            <Button
                                                text={'Download'}
                                                isBtnActive
                                                isDisabled={(this.state.flights.length == 0 || this.state.isLoading || this.state.loading)}
                                                className='new-btn-lg'
                                                onClick={() => {
                                                    this.downloadHealthReportPDF()
                                                }} />
                                        </div>



                                    </div>
                                </div>
                                <hr style={{ margin: "0px", borderTop: '1px solid rgb(0 0 0 / 10%)' }} />


                                {this.state.isLoading ? <div><LoaderComponent /></div> : <div>
                                    {/* Basic Information */}
                                    <div>
                                        <div style={{ padding: "5px 25px", fontWeight: "600", color: "#3c3c3c" }}>Basic info</div>
                                        <div className="drone-details-grid" style={{ padding: "5px 25px" }} >

                                            <div className="drone-details-card-view-details-div" style={{ padding: "10px 0px" }}>
                                                <div className="drone-details-card-view-details-div-heading">Drone ID</div>
                                                <div className="drone-details-card-view-details-div-text">{drone.drone_id ? drone.drone_id : "NA"} </div>
                                            </div>
                                            <div className="drone-details-card-view-details-div" style={{ padding: "10px 0px" }}>
                                                <div className="drone-details-card-view-details-div-heading">Drone Name</div>
                                                <div className="drone-details-card-view-details-div-text" title={drone.drone_name || "NA"}>
                                                    {drone.drone_name ? drone.drone_name : "NA"}
                                                </div>
                                            </div>
                                            <div className="drone-details-card-view-details-div" style={{ padding: "10px 0px" }}>
                                                <div className="drone-details-card-view-details-div-heading">Drone UIN</div>
                                                <div className="drone-details-card-view-details-div-text" title={drone.uin || "NA"}> {drone.uin || "NA"} </div>
                                            </div>
                                            <div className="drone-details-card-view-details-div" style={{ padding: "10px 0px" }}>
                                                <div className="drone-details-card-view-details-div-heading">Registered Date</div>
                                                <div className="drone-details-card-view-details-div-text">
                                                    {dateFormat(drone.registered_date, "dd mmm yyyy") || "NA"}
                                                </div>
                                            </div>
                                            <div className="drone-details-card-view-details-div" style={{ padding: "10px 0px" }}>
                                                <div className="drone-details-card-view-details-div-heading">Board Type</div>
                                                <div className="drone-details-card-view-details-div-text">{drone.board_type || 'NA'}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr style={{ margin: "0px", borderTop: '1px solid rgb(0 0 0 / 10%)' }} />

                                    {/* Flight Summary */}
                                    <div>
                                        <div style={{ padding: "5px 25px", fontWeight: "600", color: "#3c3c3c" }}>Flight summary</div>
                                        <div className="drone-details-grid" style={{ padding: "5px 25px" }} >
                                            <div className="drone-details-card-view-details-div" style={{ padding: "10px 0px" }}>
                                                <div className="drone-details-card-view-details-div-heading">Total Number of Hours</div>
                                                <div className="drone-details-card-view-details-div-text">  {this.state.flightsStats?.total_flight_time ? getFlightTime(this.state.flightsStats?.total_flight_time) : "0"}</div>
                                            </div>
                                            <div className="drone-details-card-view-details-div" style={{ padding: "10px 0px" }}>
                                                <div className="drone-details-card-view-details-div-heading">Total Number of flights</div>
                                                <div className="drone-details-card-view-details-div-text"> {this.state.flightsStats?.total_flights ? this.state.flightsStats?.total_flights : 0} </div>
                                            </div>
                                            <div className="drone-details-card-view-details-div" style={{ padding: "10px 0px" }}>
                                                <div className="drone-details-card-view-details-div-heading">Total Acreage</div>
                                                <div className="drone-details-card-view-details-div-text">{this.state.flightsStats?.total_acres_covered ? `${(Number(this.state.flightsStats.total_acres_covered) * 0.000247105).toFixed(6)} acre` : "0"}  </div>
                                            </div>
                                            <div className="drone-details-card-view-details-div" style={{ padding: "10px 0px" }}>
                                                <div className="drone-details-card-view-details-div-heading">Last flight date</div>
                                                <div className="drone-details-card-view-details-div-text">
                                                    {drone.last_keep_alive ? dateFormat(drone.last_keep_alive, "dd mmm yyyy") : "NA"}
                                                </div>
                                            </div>
                                            <div className="drone-details-card-view-details-div" style={{ padding: "10px 0px" }}>
                                                <div className="drone-details-card-view-details-div-heading">Total consumed liquid</div>
                                                <div className="drone-details-card-view-details-div-text">{this.state.flightsStats?.total_consumed_liquid ? `${this.state.flightsStats?.total_consumed_liquid} ml` : '0 ml'}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr style={{ margin: "0px", borderTop: '1px solid rgb(0 0 0 / 10%)' }} />

                                    {/* Software Information */}
                                    <div>
                                        <div style={{ padding: "5px 25px", fontWeight: "600", color: "#3c3c3c" }}>Software Information</div>
                                        <div className="drone-details-grid" style={{ padding: "5px 25px" }} >
                                            <div className="drone-details-card-view-details-div" style={{ padding: "10px 0px" }}>
                                                <div className="drone-details-card-view-details-div-heading">Software Version</div>
                                                <div className="drone-details-card-view-details-div-text">{
                                                    this.state.flights?.length != 0 ?
                                                        this.state.flights?.[0].green_build_number || 'NA'
                                                        : "NA"
                                                }</div>
                                            </div>

                                            <div className="drone-details-card-view-details-div" style={{ padding: "10px 0px" }}>
                                                <div className="drone-details-card-view-details-div-heading">Status</div>
                                                <div className="drone-details-card-view-details-div-text">{
                                                    this.state.flights?.length != 0 ?
                                                        this.state.flights?.[0].green_build_number === drone?.latestSoftwareVersions?.filter(v => v?.software_type == "GREEN")[0]?.version_number
                                                            ? 'Updated' : 'Not Updated'
                                                        : 'NA'}</div>
                                            </div>

                                            <div className="drone-details-card-view-details-div" style={{ padding: "10px 0px" }}>
                                                <div className="drone-details-card-view-details-div-heading">Android Version</div>
                                                <div className="drone-details-card-view-details-div-text">{drone.android_version || 'NA'}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr style={{ margin: "0px", borderTop: '1px solid rgb(0 0 0 / 10%)' }} />

                                    {/* Firmware Information */}
                                    <div>
                                        <div style={{ padding: "5px 25px", fontWeight: "600", color: "#3c3c3c" }}>Firmware Information</div>
                                        <div className="drone-details-grid" style={{ padding: "5px 25px" }} >
                                            <div className="drone-details-card-view-details-div" style={{ padding: "10px 0px" }}>
                                                <div className="drone-details-card-view-details-div-heading">Firmware Version</div>
                                                <div className="drone-details-card-view-details-div-text">{
                                                    this.state?.flights?.length != 0 ?
                                                        this.state?.flights?.[0].firmware_version_number : 'NA'}</div>
                                            </div>

                                            <div className="drone-details-card-view-details-div" style={{ padding: "10px 0px" }}>
                                                <div className="drone-details-card-view-details-div-heading">Status</div>
                                                <div className="drone-details-card-view-details-div-text">
                                                    {this.state?.flights?.length != 0 ?
                                                        this.isFirmwareVersionUpdated(this.state?.flights?.[0].firmware_version_number, drone?.latestSoftwareVersions?.filter(v => v?.software_type == "FIRMWARE")[0]?.version_number)
                                                            ? 'Updated' : 'Not Updated' : "NA"}
                                                </div>
                                            </div>
                                            <div className="drone-details-card-view-details-div" style={{ padding: "10px 0px" }}>

                                            </div>
                                        </div>
                                    </div>
                                    <hr style={{ margin: "0px", borderTop: '1px solid rgb(0 0 0 / 10%)' }} />

                                    {/* Drone Health */}
                                    <div>
                                        <div style={{ padding: "5px 25px", fontWeight: "600", color: "#3c3c3c" }}>Drone Health
                                            {/* <span className="drone-details-card-view-details-div-heading">( Most Occured Error )</span> */}
                                        </div>
                                        <div className="drone-details-grid" style={{ padding: "5px 25px" }} >
                                            <div className="drone-details-card-view-details-div" style={{ padding: "10px 0px" }}>

                                                <div className="drone-details-card-view-details-div-text" style={{
                                                    // minHeight: "20px",
                                                    maxHeight: "300px ",
                                                    overflow: "auto",
                                                    display: this.state.occuredError.length != 0 ? "flow" : "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center"
                                                }}>
                                                    {this.state.occuredError.length != 0 ?
                                                        this.state.occuredError.map(oe => {
                                                            return <div style={{ fontSize: "13px" }}> {`->`} {oe.Error_Message}
                                                                {oe.Occurrences ? <span style={{ color: oe.Occurrences > this.state.medianErrorCount ? 'red' : 'green' }} >{` (${oe.Occurrences})`}</span> : ''}
                                                                {oe.Categories && oe.Categories != 'None' ? <span style={{ color: "blue" }}>{` (${oe.Categories})`}</span> : ''}
                                                            </div>
                                                        }) :
                                                        <div> No logs available </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr style={{ margin: "0px", borderTop: '1px solid rgb(0 0 0 / 10%)' }} />

                                    {/* Hardware Health */}
                                    {/* <div>
                  <div style={{ padding: "5px 25px", fontWeight: "600", color: "#3c3c3c" }}>Hardware Health</div>
                  <div className="drone-details-grid" style={{ padding: "5px 25px" }} >
                    <div className="drone-details-card-view-details-div" style={{ padding: "10px 0px" }}>
                      <div className="drone-details-card-view-details-div-heading">Battery Performance</div>
                      <div className="drone-details-card-view-details-div-text">Battery_attenuation : {this.state.hardwareHealthData?.batteryAnalysis?.Battery_attenuation || "NA"}</div>
                    </div>
                    <div className="drone-details-card-view-details-div" style={{ padding: "10px 0px" }}>
                      <div className="drone-details-card-view-details-div-heading">GPS-Accuracy </div>
                      <div className="drone-details-card-view-details-div-text">-</div>
                    </div>
                    <div className="drone-details-card-view-details-div" style={{ padding: "10px 0px" }}>
                      <div className="drone-details-card-view-details-div-heading">Motors</div>
                      <div className="drone-details-card-view-details-div-text">-</div>
                    </div>
                    <div className="drone-details-card-view-details-div" style={{ padding: "10px 0px" }}>
                      <div className="drone-details-card-view-details-div-heading">Compass</div>
                      <div className="drone-details-card-view-details-div-text">-</div>
                    </div>
                    <div className="drone-details-card-view-details-div" style={{ padding: "10px 0px" }}>
                      <div className="drone-details-card-view-details-div-heading">Vibrations</div>
                      <div className="drone-details-card-view-details-div-text">-</div>
                    </div>
                  </div>
                </div>
                <hr style={{ margin: "0px", borderTop: '1px solid rgb(0 0 0 / 10%)' }} /> */}

                                    {/* Recommendation */}
                                    <div>
                                        <div style={{ padding: "5px 25px", fontWeight: "600", color: "#3c3c3c" }}>Recommendation</div>
                                        <div className="drone-details-grid" style={{ padding: "5px 25px" }} >

                                            {this.state.flights.length != 0
                                                && (this.state.flights?.[0].green_build_number != drone?.latestSoftwareVersions?.filter(v => v?.software_type == "GREEN")[0]?.version_number
                                                    || !this.isFirmwareVersionUpdated(this.state?.flights?.[0].firmware_version_number, drone?.latestSoftwareVersions?.filter(v => v?.software_type == "FIRMWARE")[0]?.version_number))
                                                ?
                                                <div className="drone-details-card-view-details-div" style={{ padding: "10px 0px" }}>
                                                    <div className="drone-details-card-view-details-div-heading">Software Upgrade </div>
                                                    <div className="drone-details-card-view-details-div-text">
                                                        <div>
                                                            {this.state.flights?.length != 0 ?
                                                                this.state.flights?.[0].green_build_number == drone?.latestSoftwareVersions?.filter(v => v?.software_type == "GREEN")[0]?.version_number
                                                                    ? '' : `- Please Upgrade Software Version (${drone?.latestSoftwareVersions?.filter(v => v?.software_type == "GREEN")[0]?.version_number})` : ''}</div>
                                                        <div>{this.state.flights?.length != 0 ?
                                                            this.isFirmwareVersionUpdated(this.state?.flights?.[0].firmware_version_number, drone?.latestSoftwareVersions?.filter(v => v?.software_type == "FIRMWARE")[0]?.version_number)
                                                                ? '' : `- Please Upgrade Firmware Version (${drone?.latestSoftwareVersions?.filter(v => v?.software_type == "FIRMWARE")[0]?.version_number})` : ""}</div>
                                                    </div>
                                                </div>

                                                : <div style={{ display: 'flex', justifyContent: "center" }}> No Recommendation
                                                </div>}

                                        </div>
                                    </div>
                                    <hr style={{ margin: "0px", borderTop: '1px solid rgb(0 0 0 / 10%)' }} />

                                    {/* Overall Health */}
                                    {/* <div>
                  <div style={{ padding: "5px 25px", fontWeight: "600", color: "#3c3c3c" }}>Overall Health</div>
                  <div className="drone-details-grid" style={{ padding: "5px 25px" }} >
                    <div className="drone-details-card-view-details-div" style={{ padding: "10px 0px" }}>
                      <div className="drone-details-card-view-details-div-heading">Crash </div>
                      <div className="drone-details-card-view-details-div-text">- </div>
                    </div>


                  </div>
                </div>
                <hr style={{ margin: "0px", borderTop: '1px solid rgb(0 0 0 / 10%)' }} /> */}
                                </div>}
                            </div>

                            <div className="drone-mgt-table-height">
                                <TableView
                                    pageNumber={this.state.pageNumber}
                                    isLoading={this.state.isLoading}
                                    onStateChange={(changedState) => {
                                        this.setState((state) => ({
                                            ...state,
                                            pageNumber: changedState.pageNumber,
                                        }), () => {
                                            cookies.set('Drone_Health_Report',
                                                {
                                                    selectedTab: this.state.selectedTab,
                                                    dateRange: this.state.dateRange,
                                                    pageNumber: this.state.pageNumber
                                                },
                                                { path: "/weekly_drone_health_report" })
                                            this.getFlightsData()
                                        })
                                    }}
                                    headingLeft={`Flights`}
                                    onClickRow={(flight) => {
                                        this.props.history.push('/remote_mission/flight_details', {
                                            ...flight,
                                            isOwner: this.state.activeTeam.isOwner,
                                            drone: this.state.drone,
                                            user: user,
                                            flightId: flight.id,
                                            planName: flight.plan_name,
                                            projectName: flight.project_name,
                                            date: flight.end_time,
                                            startTime: flight.start_time,
                                            end_time: flight.end_time,
                                            streamKey: flight.streamkey,
                                            areaCovered: flight.area_covered,
                                            areaCoveredMethod: flight.area_calculation_method,
                                            distanceCovered: flight.distance_covered,
                                            droneId: flight.drone_id,
                                            firmwareVersion: flight.firmware_version_number,
                                            greenBuildNumber: flight.green_build_number,
                                            droneName: flight.drone_name,
                                            droneUIN: flight.uin,
                                            software_edition: flight.software_edition
                                        });
                                    }}
                                    onClickLink={(flight) => {
                                        this.props.history.push("/drone_control_center/fly_view", { flight: flight.id, user: this.state.user, replyData: 0 })
                                    }}
                                    columns={[
                                        { Header: "Project Name", accessor: "project_name", width: '11%' },
                                        { Header: "Plan Name", accessor: "plan_name", width: '11%' },
                                        { Header: "Start Time", accessor: "start_time", width: '11%', isDate: true, },
                                        { Header: "End Time", accessor: "end_time", width: '11%', isDate: true, },
                                        { Header: "Total Flight Time", accessor: "total_flight_time", width: '11%' },
                                        { Header: "Distance (M)", accessor: "distance_covered", width: '11%' },
                                        { Header: "Area (Acr)", accessor: "area_covered", width: '11%' },
                                        { Header: "Replay", accessor: "link_data", width: '11%', isLink: true, isCenter: true },
                                    ]}
                                    count={this.state.totalFlights || 0}
                                    data={this.state.flights?.map((flight) => {
                                        return ({
                                            ...flight,
                                            total_flight_time: getFlightTime(flight.total_flight_time),
                                            area_covered: (Number(flight.area_covered) / 4046.85642).toFixed(4),
                                            link_data: { img: flightReplay }
                                        })
                                    }) || []}
                                    activities={this.state.activeTeam.isOwner && {
                                        disableDelete: !this.state.permissions?.[GCS_FEATURES.PLANS]?.DELETE,
                                        onClickDelete: (flight) => {
                                            this.setState({
                                                deleteData: flight,
                                                activityPopup: {
                                                    open: true,
                                                    msg: 'Are you sure you want to delete selected flight and related data. (ie. streaming video, images, flight logs) ?',
                                                    item: "WARNING",
                                                    icon: "WARNING",
                                                }
                                            })
                                        }
                                    }}
                                />
                            </div>
                        </>}
                    </div>
                </div >
            </div >
        ) : (
            <div />
        );
    }
}

export default withRouter(WeeklyDrnHealthReport)
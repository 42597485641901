import React, { Component } from 'react';
import * as API from './api.js';
import Loader from './icons/loader.svg';
import { Link } from 'react-router-dom';
import dateFormat from 'dateformat';
import RenewSubscription from './subscription/Reusable/RenewSubscription.js';
import AppConstants from './AppConstants.js';

const PAGE_SIZE = 10000
export default class Invoices extends Component {
    state = {
        user: {},
        type: "",
        org: {},
        loading: true,
        pageNumber: 1,
        totalCount: 0,
    }

    getInvoices() {
        API.getMyInvoices(PAGE_SIZE, this.state.pageNumber).then(res => {
            let { invoices, totalCount } = res
            this.setState({
                invoices,
                totalCount
            }, () => {
                this.setState({
                    loading: false
                })
            })
        }).catch(e => {
            alert("Failed to get your invoices. Try again later. Error: " + e)
        })
    }

    componentDidMount() {
        this.getInvoices()
    }

    componentWillMount() {
        document.title = "My Invoices - AeroMegh";
        if (this.props) {
            let { user, type } = this.props;
            if (!user.sub) {
                user.sub = {
                    "plan": "aeromegh_plan_free"
                }
            }
            this.setState((state) => ({
                ...state,
                user,
                type,
            }), () => {
            })
        } else {
            API.getUserType().then(([blah, type, user]) => {
                if (!user.sub) {
                    user.sub = {
                        "plan": "aeromegh_plan_free"
                    }
                }
                this.setState((state) => ({
                    ...state,
                    user,
                    type
                }), () => {
                })
            }, (e) => {
                window.location.replace("/login");
            })
        }
    }

    render() {
        let { user, type, model } = this.state
        return (
            user.username ? <div className="wrapper">
                <div className='setting-containt' >
                    {this.state.loading ?
                        <div style={{ height: "50vh", width: "65vw", display: "flex", textAlign: "center" }}>
                            <img src={Loader} style={{ height: "30vh", width: "100vw", margin: "auto" }} />
                        </div>
                        : <div>
                            <div style={{ fontSize: "15px", fontWeight: "500" }}>Services plans</div>
                            <div style={{ overflow: "auto", height: 'calc(100% - 80px)', position: 'absolute', width: '93%', marginTop: '20px' }}>
                                <table className="customers-table" style={{ marginTop: "0px" }}>
                                    <col style={{ width: '15%' }} />
                                    <col style={{ width: '35%' }} />
                                    <col style={{ width: '20%' }} />
                                    <col style={{ width: '15%' }} />
                                    <col style={{ width: '15%' }} />
                                    <tr className="customers-table-header-row" style={{ background: "#E1EDFF" }}>
                                        <th>Invoice Number</th>
                                        <th>Product</th>
                                        <th>Date & Time</th>
                                        <th>Amount</th>
                                        <th>Status</th>
                                    </tr>
                                    {this.state.invoices.length === 0 ?
                                        <tr className="customers-table-data-row" align="center" style={{ height: "45px" }}>
                                            <td colspan="100%">No invoices available</td>
                                        </tr> :
                                        this.state.invoices.map((invoice, i) => <tr className="customers-table-data-row">
                                            <td>
                                                <Link to={{
                                                    pathname: "/invoice",
                                                    state: { ...this.state, invoiceId: invoice.id }
                                                }} style={{
                                                    color: "#707683"
                                                }}>
                                                    {invoice.invoice_number}
                                                </Link>
                                            </td>
                                            <td>{invoice.product ? invoice.product : "-"}</td>
                                            <td>{dateFormat(new Date(invoice.created_time), 'dd mmm yyyy')}{new Date(invoice.created_time).toLocaleString().split(',')[1]}</td>
                                            <td>{invoice?.payment?.currency === "inr" ? "₹" : invoice?.payment?.currency === "usd" ? "$" : "₹"}{invoice.price - (invoice.price * invoice.discount / 100)}</td>
                                            <td><div style={{ textTransform: 'capitalize', color: invoice.payment?.payment_status == 'paid' ? 'green' : 'red', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                {invoice.payment?.payment_status == 'due' ? 'Pending' : invoice.payment?.payment_status}
                                                {invoice.payment?.payment_status == 'due' && <RenewSubscription data={invoice} user={this.state.user} />}</div>
                                            </td>
                                        </tr>)}
                                </table>
                            </div>
                        </div>
                    }</div>
            </div > : <div />
        );
    }
}
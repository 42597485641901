import React from 'react';
import SingleMap from './ReusableComponents/map';
import droneLive from './icons/droneGreen.png'
import droneOffline from './icons/droneGrey.png'
import portOffline from './icons/port-offline.png'
import portOnline from './icons/port-online.png'
import locationIcon from './icons/locationIcon.png'
import roadMapView from './assets/Images/roadMapViewzz.svg';
import sateliteView from './assets/Images/sateliteView.svg'
import L, { layerGroup, marker } from "leaflet"
import "leaflet-draw";
import "leaflet-editable"
import "leaflet.path.drag"
import Loader from './icons/loader.svg';
import * as API from './api.js';
import AppConstants, { MAP_URLS } from './AppConstants.js';
import LoaderComponent from './ReusableComponents/LoaderComponent.js';
import dateFormat from 'dateformat';
import 'leaflet/dist/leaflet.css';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import 'leaflet.markercluster';
import { v4 as uuidv4 } from 'uuid';

const INITIAL_LAT = -83.804303
const INITIAL_LON = 74.489302
let extendBounds = 0.00075
var map = {}
export default class MapComponent extends React.Component {
  state = {
    loading: true,
    map: undefined,
    user: this.props.user,
    flightReplayMode: this.props.flightReplayMode || false,
    replayDroneID: this.props.replayDroneID || "",
    center: { lat: 19.993982, lng: 73.790416 },
    didDupMarkerFix: false,
    replayTeleData: {
      lat: INITIAL_LON,
      lon: INITIAL_LAT
    },
    polylineColor: '#FFFFFF',
    drones: {},
    baseLayer: '',
    activeBaseLayer: 'satelite',
    hasCentered: this.props.recenterMap || false,
    locationsData: this.props.locationsData || [],
    offLineMarkersCluster: L.markerClusterGroup({
      spiderfyOnMaxZoom: true,
      showCoverageOnHover: false,
      zoomToBoundsOnClick: true,
      iconCreateFunction: (cluster) => {
        let count = cluster.getChildCount()
        return L.divIcon({
          html: '<div><span>' + count + '</span></div>',
          className: 'marker-cluster marker-cluster-medium',
          iconSize: L.point(40, 40)
        });
      }
    }),
    onLineMarkersCluster: L.markerClusterGroup({
      spiderfyOnMaxZoom: true,
      showCoverageOnHover: false,
      zoomToBoundsOnClick: true,
      iconCreateFunction: (cluster) => {
        let count = cluster.getChildCount()
        return L.divIcon({
          html: '<div><span>' + count + '</span></div>',
          className: 'marker-cluster marker-cluster-small',
          iconSize: L.point(40, 40)
        });
      }
    }),
  };

  setBaseLayerToState = (baseLayer) => {
    this.setState({ baseLayer })
  }

  switchBaseLayer = () => {
    const { map1 } = this.state
    this.setState({ activeBaseLayer: this.state.activeBaseLayer == "satelite" ? "terrain" : "satelite" }, () => {
      if (this.state.activeBaseLayer === 'terrain') {
        this.setState({ polylineColor: '#2989CF' })
      } else if (this.state.activeBaseLayer === 'satelite') {
        this.setState({ polylineColor: '#FFFFFF' })
      }
      this.state.baseLayer.setUrl(this.state.activeBaseLayer === "satelite" ? MAP_URLS.SATELITE : MAP_URLS.ROAD_MAP)
    })

  }

  drawBoundaries = () => {
    if (this.props.fenceBoundary) {
      let fenceBoundary = JSON.parse(this.props.fenceBoundary)
      let polygonView = new L.Polygon([fenceBoundary], {
        color: 'red',
        fillOpacity: 0,
      }).addTo(this.state.map)
      let bounds = polygonView.getBounds()
      this.state.map.fitBounds(bounds);
    }
    if (this.props.planBoundary) {
      let planBoundary = JSON.parse(this.props.planBoundary)
      L.polygon([planBoundary], {
        color: "yellow",
        fillOpacity: 0,
      }).addTo(this.state.map)
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.drones !== this.props.drones) {
      if (!this.state.didDupMarkerFix && (Object.keys(this.props.drones).length > 0 || (this.props.replayTeleData && this.props.replayTeleData.lat))) {
        console.log("did update ran")
        let drones = JSON.parse(JSON.stringify(this.props.drones))
        let count1 = 0
        Object.keys(drones).forEach((drone) => {
          drones[drone].teleData = {
            ...drones[drone].teleData,
            lat: INITIAL_LAT,
            lon: INITIAL_LON
          }
          count1++
          if (count1 == Object.keys(drones).length) {
            this.setState((prevState) => ({
              ...prevState,
              drones,
              replayTeleData: {
                lat: INITIAL_LAT,
                lon: INITIAL_LON
              },
            }), () => {
              this.setState((prevState) => ({
                ...prevState,
                drones: this.props.drones,
                replayTeleData: this.props.replayTeleData,
                didDupMarkerFix: true
              }))
            })
          }
        })
      } else if (this.state.didDupMarkerFix) {
        this.setState((prevState) => ({
          ...prevState,
          drones: this.props.drones,
          replayTeleData: this.props.replayTeleData
        }), () => {
          this.initMap()
        })
      }

    }
  }

  componentDidMount() {
    this.checkStatusInterval = setInterval(() => {
      Object.keys(this.state.drones).forEach((drone, i) => {
        if (this.state.drones[drone].lastActive) {
          let currentTimestamp = new Date();
          let droneLastActive = new Date(this.state.drones[drone].lastActive);
          if (((currentTimestamp - droneLastActive) / 1000) <= 60) {
            this.setState((prevState) => ({
              ...prevState,
              drones: {
                ...prevState.drones,
                [drone]: {
                  ...prevState.drones[drone],
                  online: true,
                }
              },
              loading: false,
            }))
          } else {
            this.setState((prevState) => ({
              ...prevState,
              drones: {
                ...prevState.drones,
                [drone]: {
                  ...prevState.drones[drone],
                  online: false,
                }
              },
              loading: false,
            }))
          }
        } else {
          this.setState((prevState) => ({
            ...prevState,
            drones: {
              ...prevState.drones,
              [drone]: {
                ...prevState.drones[drone],
                online: false,
              }
            },
            loading: false,
          }))
        }
      })
    }, 6000)

    if (this.props.drones && !this.state.didDupMarkerFix && (Object.keys(this.props.drones).length > 0 || (this.props.replayTeleData && this.props.replayTeleData.lat))) {
      let drones = JSON.parse(JSON.stringify(this.props.drones))
      Object.keys(drones).forEach((drone) => {
        drones[drone].teleData = {
          ...drones[drone].teleData,
          lat: INITIAL_LAT,
          lon: INITIAL_LON
        }
      })

      this.setState((prevState) => ({
        ...prevState,
        drones,
        loading: false,
        hasCentered: true,
        replayTeleData: {
          lat: INITIAL_LAT,
          lon: INITIAL_LON
        },
      }), () => {
        this.setState((prevState) => ({
          ...prevState,
          drones: this.props.drones,
          loading: false,
          replayTeleData: this.props.replayTeleData,
          didDupMarkerFix: true
        }))
      })
    } else if (this.state.didDupMarkerFix) {
      this.setState((prevState) => ({
        ...prevState,
        drones: this.props.drones,
        loading: false,
        hasCentered: true,
        replayTeleData: this.props.replayTeleData
      }))
    } else if (this.props.locationsData) {
      this.setState({ loading: false, hasCentered: true, })
    } else {
      this.setState({ loading: false, hasCentered: true, })
    }

  }

  componentWillUnmount() {
    clearInterval(this.checkStatusInterval);
  }


  boundsExtendDrones = (bounds, callback) => {
    if (this.state.drones && Object.keys(this.state.drones).length > 0) {
      Object.keys(this.state.drones).forEach((drone, i) => {
        if (this.state.drones[drone].teleData && this.state.drones[drone].teleData.lat && this.state.drones[drone].teleData.lon) {
          bounds.extend({ lat: this.state.drones[drone].teleData.lat, lng: this.state.drones[drone].teleData.lon });
        }
        if (i == Object.keys(this.state.drones).length - 1) {
          callback(bounds)
        }
      });
    } else {
      callback(bounds)
    }
  }

  boundsExtendLocations = (bounds, callback) => {
    if (this.props.locationsData && this.props.locationsData.length > 0) {
      this.props.locationsData.forEach((location, i) => {
        if (location.tele_data && location.tele_data.lat && location.tele_data.lon
          && location.tele_data.lat != 'NaN' && location.tele_data.lon != 'NaN' && location.tele_data.lat != NaN && location.tele_data.lon != NaN
          && location.tele_data.lat != 'null' && location.tele_data.lon != 'null' && location.tele_data.lat != null && location.tele_data.lon != null
        ) {
          bounds.extend({ lat: location.tele_data.lat, lng: location.tele_data.lon });
        }
        if (i == this.props.locationsData.length - 1) {
          callback(bounds)
        }
      });
    } else {
      callback(bounds)
    }
  }


  recenter = () => {
    try {
      if (this.props.fenceBoundary) {
        let fenceBoundary = JSON.parse(this.props.fenceBoundary)
        let polygonView = new L.Polygon([fenceBoundary])
        let bounds = polygonView.getBounds()
        this.state.map.fitBounds(bounds);
        this.setState({ hasCentered: false, })
      } else {
        let bounds = new L.latLngBounds()
        this.boundsExtendDrones(bounds, (bounds2) => {
          this.boundsExtendLocations(bounds2, (bounds3) => {
            this.state.map.fitBounds(bounds3)
            this.setState({ hasCentered: false, })
          })
        })
      }
    } catch (e) {
      console.log(e)
    }
  }

  initMap1 = () => {
    this.initMap()
    this.drawBoundaries()
    if (!this.props.recenterMap || !this.state.replayDroneID) {
      this.recenter()
    }
  }
  droneOffline = {}
  droneOnline = {}

  initMap = () => {
    if (this.state.map) {
      if (this.props.locationsData && this.props.locationsData.length > 0) {
        this.props.locationsData.map((location, i) => {
          if (location.tele_data && location.tele_data.lat && location.tele_data.lon
            && location.tele_data.lat != 'NaN' && location.tele_data.lon != 'NaN' && location.tele_data.lat != NaN && location.tele_data.lon != NaN
            && location.tele_data.lat != 'null' && location.tele_data.lon != 'null' && location.tele_data.lat != null && location.tele_data.lon != null) {
            let lat = parseFloat(location.tele_data.lat)
            let lng = parseFloat(location.tele_data.lon)
            if (!isNaN(lat) && !isNaN(lng)) {
              if (this.props.showTooltip) {
                let icon = new L.Marker({ lat: parseFloat(location.tele_data.lat), lng: parseFloat(location.tele_data.lon) }, {
                  clickable: true,
                  icon: L.icon({
                    iconUrl: droneOffline,
                    iconSize: [45, 45],
                    iconAnchor: [22, 22]
                  }),
                })
                  .bindTooltip(`<div style="font-size:13px">
                    <div><strong>Drone Id:</strong> ${location?.drone_id || 'N/A'}</div>
                    <div><strong>UIN:</strong> ${location?.uin || 'N/A'}</div>
                    <div><strong>Last Flight:</strong> ${dateFormat(location?.last_keep_alive, 'dd/mm/yyyy hh:mm:ss TT') || 'N/A'}</div>
                    <div><strong>Last Cordinates:</strong> ${location?.latLng || 'N/A'}</div>
                    <div style="white-space: normal"><strong>Manufacturer:</strong> ${location?.username || 'N/A'}</div>
                    <div style="white-space: normal"><strong>Location:</strong> ${location?.location || 'N/A'}</div>                  
                  </div>`, {
                    permanent: false,
                    // direction: 'top',
                    offset: L.point(20, 0)
                  })
                  .on("click", (e) => {
                    this.props.markerClickCallback(location)
                  })
                this.state.offLineMarkersCluster.addLayer(icon)
              } else if (this.props.OEMDroneShowTooltip) {
                let icon = new L.Marker({ lat: parseFloat(location.tele_data.lat), lng: parseFloat(location.tele_data.lon) }, {
                  clickable: true,
                  icon: L.icon({ iconUrl: droneOffline, iconSize: [45, 45], iconAnchor: [22, 22] }),
                })
                  .bindTooltip(`<div style="font-size:13px">
                    <div><strong>OEM Email:</strong> ${location?.oem || 'N/A'}</div>
                    <div><strong>License Id:</strong> ${location?.license_id || 'N/A'}</div>
                    <div><strong>UIN:</strong> ${location?.uin != 'null' ? location?.uin : 'N/A' || 'N/A'}</div>
                    <div><strong>Last Flight:</strong> ${dateFormat(location?.datetime, 'dd/mm/yyyy hh:mm:ss TT') || 'N/A'}</div>
                    <div><strong>Last Cordinates:</strong> ${location?.latLng || 'N/A'}</div>
                    <div style="white-space: normal"><strong>Location:</strong> ${location?.location || 'N/A'}</div>                  
                  </div>`, {
                    permanent: false,
                    // direction: 'top',
                    offset: L.point(20, 0)
                  })
                  .on("click", (e) => {
                    this.props.markerClickCallback(location)
                  })
                this.state.offLineMarkersCluster.addLayer(icon)
              } else {
                let circleIcon = new L.Marker({ lat: parseFloat(location.tele_data.lat), lng: parseFloat(location.tele_data.lon) }, {
                  clickable: true,
                  icon: L.icon({
                    iconUrl: locationIcon,
                    iconSize: [45, 45],
                    iconAnchor: [22, 22]
                  }),
                })
                this.state.offLineMarkersCluster.addLayer(circleIcon)
              }
            }
          }
        })
        this.state.map.addLayer(this.state.offLineMarkersCluster);
      } else {
        Object.keys(this.state.drones).map((drone) => {
          if (this.state.drones[drone].teleData && typeof this.state.drones[drone].teleData.lat == "string") this.state.drones[drone].teleData.lat = parseFloat(this.state.drones[drone].teleData.lat);
          if (this.state.drones[drone].teleData && typeof this.state.drones[drone].teleData.lon == "string") this.state.drones[drone].teleData.lon = parseFloat(this.state.drones[drone].teleData.lon);
          if (this.state.drones[drone].teleData && this.state.drones[drone].teleData?.lat && this.state.drones[drone].teleData?.lon && (this.state.drones[drone].teleData.lat != 0 || this.state.drones[drone].teleData.lat != 0)) {
            if (this.state.drones[drone].online) {
              if (this.droneOffline && this.droneOffline[drone]) {
                if (this.state.offLineMarkersCluster.hasLayer(this.droneOffline[drone])) {
                  this.state.offLineMarkersCluster.removeLayer(this.droneOffline[drone]);
                }
                this.droneOffline[drone].remove()
              }

              if (this.droneOnline[drone]) {
                return (this.droneOnline[drone].setLatLng(new L.latLng(this.state.drones[drone].teleData?.lat, this.state.drones[drone].teleData?.lon)))
              } else {
                let onlineDrone = new L.Marker({ lat: this.state.drones[drone].teleData?.lat, lng: this.state.drones[drone].teleData?.lon }, {
                  clickable: true,
                  icon: L.icon({
                    iconUrl: droneLive,
                    iconSize: [45, 45],
                    iconAnchor: [22, 22]
                  })
                })
                  // .addTo(this.state.map)
                  .on("click", (e) => {
                    this.props.markerClickCallback(drone, this.state.drones[drone].userid)
                  })
                  .bindTooltip(`<div style="font-size:13px">
                      <div><strong>Drone Id:</strong> ${this.state.drones[drone]?.drone_id || 'N/A'}</div>
                      <div style="white-space: normal"><strong>UIN:</strong> ${this.state.drones[drone]?.uin || '-'}</div>
                      ${this.props.isAdmin ? `<div style="white-space: normal"><strong>User Name:</strong> ${this.state.drones[drone]?.user_name || 'N/A'}</div>` : ''}             
                    </div>`, {
                    permanent: false,
                    opacity: this.props.isAdmin || this.props.control ? 1 : -1,
                    // direction: 'top',
                    offset: L.point(20, 0)
                  })
                this.droneOnline[drone] = onlineDrone
                this.state.onLineMarkersCluster.addLayer(onlineDrone)
              }
            } else {
              if (this.droneOnline[drone]) {
                if (this.state.onLineMarkersCluster.hasLayer(this.droneOnline[drone])) {
                  this.state.onLineMarkersCluster.removeLayer(this.droneOnline[drone]);
                }
                this.droneOnline[drone].remove()
              }
              if (this.droneOffline[drone]) {
                return (this.droneOffline[drone].setLatLng(new L.latLng(this.state.drones[drone].teleData?.lat, this.state.drones[drone].teleData?.lon)))
              } else {
                let offlineDrone = new L.Marker({ lat: this.state.drones[drone].teleData.lat, lng: this.state.drones[drone].teleData.lon }, {
                  icon: L.icon({
                    iconUrl: droneOffline,
                    iconSize: [45, 45],
                    iconAnchor: [22, 22]
                  })
                })
                  // .addTo(this.state.map)
                  .bindTooltip(`<div style="font-size:13px">
                  <div><strong>Drone Id:</strong> ${this.state.drones[drone]?.drone_id || 'N/A'}</div>
                   <div style="white-space: normal"><strong>UIN:</strong> ${this.state.drones[drone]?.uin || '-'}</div>
                  ${this.props.isAdmin ? `<div style="white-space: normal"><strong>User Name:</strong> ${this.state.drones[drone]?.user_name || 'N/A'}</div>` : ''}              
                </div>`, {
                    permanent: false,
                    opacity: this.props.isAdmin || this.props.control ? 1 : -1,
                    // direction: 'top',
                    offset: L.point(20, 0)
                  })
                this.droneOffline[drone] = offlineDrone
                this.state.offLineMarkersCluster.addLayer(offlineDrone)
              }
            }
          }
        })
        this.state.map.addLayer(this.state.offLineMarkersCluster);
        this.state.map.addLayer(this.state.onLineMarkersCluster);
      }

      if ((this.props.recenterMap || this.props.replayDroneID) && this.state.hasCentered) {
        this.recenter()
      }

      if (this.props.trailLogs) {
        this.dronetrail ? this.dronetrail.setLatLngs(this.props.trailLogs) :
          this.dronetrail = L.polyline(this.props.trailLogs, {
            color: this.state.polylineColor,
            weight: 2,
          }).addTo(this.state.map)
      }
    }
  }

  render() {
    return (
      <>
        {this.state.loading ? <LoaderComponent /> :
          <>
            {this.props.showIcons && <>
              <img
                src="https://static.thenounproject.com/png/1746022-200.png"
                className='slide mapCenterIcon'
                style={{ bottom: this.props.replayPlayback ? "160px" : "125px", }}
                onClick={this.recenter}
              />
              <img className='slide mapSwitchIcon' style={{ bottom: this.props.replayPlayback ? "70px" : "24px", }} src={this.state.activeBaseLayer === "satelite" ? roadMapView : sateliteView} onClick={() => {
                this.switchBaseLayer()
              }} />
            </>}
            <SingleMap
              id={uuidv4()}
              setBaseLayerToState={this.setBaseLayerToState}
              initCenter={[this.state.center.lat, this.state.center.lng]}
              initZoom={22}
              handleMapClick={this.handleMapClick}
              maxZoom={22}
              setMap={(map) => {
                this.setState({ map }, this.initMap1)
              }}
            />
          </>}
      </>
    )
  }
}

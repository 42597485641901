import React, { useEffect, useState } from 'react';
import * as d3 from 'd3'
import Plotly from 'plotly.js/dist/plotly.min';
import colormap from 'colormap';
import captureIcon from '../../icons/logToPng.svg'
import panIcon from '../../icons/logPan.svg'
import panActiveIcon from '../../icons/logPanActive.svg'
import zoomInIcon from '../../icons/logZoomIn.svg'
import zoomOutIcon from '../../icons/logZoomOut.svg'
import autoScaleIcon from '../../icons/logAutoScale.svg'
import resetAxesIcon from '../../icons/logResetAxes.svg'

const LogAnalyzerPlot = ({ logData, modeChanges, graphName, expressions }) => {
    const Color = require('color')
    const valueformat = '.2~f'
    const allColorsV = ['#FF5733', '#FFC300', '#3988F9', '#C70039', '#074B5E', '#581845', '#008000', '#A6FF00', '#800080', '#FF0063', '#00FFFF', '#808080']
    const allAxisV = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]

    let annotationsEvents = []
    const annotationsModes = []
    let annotationsParams = []
    // let gd = null
    let cursor

    const plotOptions = {
        legend: {
            x: 0.97,
            y: 0.98,
            xanchor: 'right',
            yanchor: 'top',
            itemsizing: 'constant',
            traceorder: 'normal',
        },
        showlegend: false,
        plot_bgcolor: '#f8f8f8',
        paper_bgcolor: 'white',
        margin: { t: 5, l: 15, b: 40, r: 5 },
        xaxis: {
            title: 'Time since boot',
            domain: [0.15, 0.85],
            rangeslider: { visible: false },
            tickformat: valueformat,
        },
        yaxis: {
            titlefont: {
                color: '#1f77b4'
            },
            tickfont: {
                color: '#1f77b4', size: 12
            },
            anchor: 'free',
            position: 0.03,
            autotick: true,
            showline: true,
            ticklen: 3,
            tickangle: 45,
            tickformat: valueformat

        },
        yaxis2: {
            titlefont: { color: '#ff7f0e' },
            tickfont: { color: '#ff7f0e', size: 12 },
            anchor: 'free',
            overlaying: 'y',
            side: 'left',
            position: 0.07,
            autotick: true,
            showline: true,
            ticklen: 3,
            tickangle: 45,
            tickformat: valueformat
        },
        yaxis3: {
            titlefont: { color: '#2ca02c' },
            tickfont: { color: '#2ca02c' },
            anchor: 'free',
            overlaying: 'y',
            side: 'left',
            position: 0.11,
            autotick: true,
            showline: true,
            ticklen: 3,
            tickangle: 45,
            tickformat: valueformat
        },
        yaxis4: {
            titlefont: { color: '#d62728' },
            tickfont: { color: '#d62728' },
            anchor: 'free',
            overlaying: 'y',
            side: 'left',
            position: 0.92,
            autotick: true,
            showline: true,
            ticklen: 3,
            tickangle: 45,
            tickformat: valueformat
        },
        yaxis5: {
            titlefont: { color: '#9467BD' },
            tickfont: { color: '#9467BD' },
            anchor: 'free',
            overlaying: 'y',
            side: 'left',
            position: 0.96,
            autotick: true,
            showline: true,
            ticklen: 3,
            tickangle: 45,
            tickformat: valueformat
        },
        yaxis6: {
            titlefont: { color: '#8C564B' },
            tickfont: { color: '#8C564B' },
            anchor: 'free',
            overlaying: 'y',
            side: 'left',
            position: 1.0,
            autotick: true,
            showline: true,
            ticklen: 3,
            tickangle: 45,
            tickformat: valueformat
        }

    }

    let plotInstance = null
    let cache = {}
    let expressionsLocal = []

    const [gd, setGd] = useState(null)
    const [cssColorsV, setCssColorsV] = useState([])
    const [datasets, setDatasets] = useState([])
    const [panActive, setPanActive] = useState(false)
    const [graphRange, setGraphRange] = useState()

    const MAX_ZOOM_IN_SCALE = 0.5;

    const enforceZoomLimit = (axis, range) => {
        const rangeSize = range[1] - range[0];
        if (rangeSize < MAX_ZOOM_IN_SCALE) {
            const midPoint = (range[0] + range[1]) / 2;
            const newRange = [midPoint - MAX_ZOOM_IN_SCALE / 2, midPoint + MAX_ZOOM_IN_SCALE / 2];
            const relayoutUpdate = {};
            relayoutUpdate[`${axis}.range`] = newRange;
            Plotly.relayout(gd, relayoutUpdate);
        }
    };

    const onRangeChanged = (event) => {
        if (event !== undefined) {
            if (event['xaxis.range']) {
                const yRangeLocal = event['xaxis.range'];
                enforceZoomLimit('xaxis', yRangeLocal);
            } else if (event['xaxis.range[0]'] && event['xaxis.range[1]']) {
                const yRangeLocal = [event['xaxis.range[0]'], event['xaxis.range[1]']];
                enforceZoomLimit('xaxis', yRangeLocal);
            } else if (event['xaxis.autorange']) {
                const yRangeLocal = [gd.layout.xaxis.range[0], gd.layout.xaxis.range[1]];
                enforceZoomLimit('xaxis', yRangeLocal);
            }

            if (event['yaxis.range']) {
                const yRangeLocal = event['yaxis.range'];
                enforceZoomLimit('yaxis', yRangeLocal);
            } else if (event['yaxis.range[0]'] && event['yaxis.range[1]']) {
                const yRangeLocal = [event['yaxis.range[0]'], event['yaxis.range[1]']];
                enforceZoomLimit('yaxis', yRangeLocal);
            } else if (event['yaxis.autorange']) {
                const yRangeLocal = [gd.layout.yaxis.range[0], gd.layout.yaxis.range[1]];
                enforceZoomLimit('yaxis', yRangeLocal);
            }
        }
    };

    const calculateXAxisDomain = () => {
        let start = 0.02
        let end = 0.98
        for (const field of expressionsLocal) {
            if (field.axis === 0) {
                start = Math.max(start, 0.03)
            } else if (field.axis === 1) {
                start = Math.max(start, 0.07)
            } else if (field.axis === 2) {
                start = Math.max(start, 0.11)
            } else if (field.axis === 5) {
                end = Math.min(end, 0.96)
            } else if (field.axis === 4) {
                end = Math.min(end, 0.92)
            } else if (field.axis === 3) {
                end = Math.min(end, 0.88)
            }
        }

        return [start, end]
    }

    const addGaps = (data) => {
        const newData = { x: [], y: [], isSwissCheese: false }
        let lastx = data.x[0]
        const totalPoints = data.x.length
        let totalGaps = 0
        for (let i = 0; i < data.x.length; i++) {
            if ((data.x[i] - lastx) > 3000000) {
                newData.x.push(data.x[i] - 1)
                newData.y.push(null)
                totalGaps += 1
            }
            newData.x.push(data.x[i])
            newData.y.push(data.y[i])
            lastx = data.x[i]
        }
        if (totalGaps > (totalPoints / 2)) {
            newData.isSwissCheese = true
        }
        return newData
    }

    const evaluateExpression = (expression1) => {
        if (expression1 in cache) {
            return cache[expression1]
        }
        const x = []
        const y = []
        for (const log of logData) {
            x.push(log.timeFromStart)
            y.push(Number(log[expression1]))
        }
        const data = addGaps({ x: x, y: y })
        cache[expression1] = data
        return data
    }

    const addParamChanges = () => {
        let i = -300
        annotationsParams = []
        const firstFetch = new Set()
        let startAt = null
        // for (const change of paramsV.changeArray) {
        //     if (!firstFetch.has(change[1])) {
        //         firstFetch.add(change[1])
        //     } else {
        //         startAt = change[0]
        //         break
        //     }
        // }
        let last = [0, 0]
        // for (const change of paramsV.changeArray) {
        //     if (change[0] < startAt) {
        //         continue
        //     }
        //     // This takes care of repeated param changed logs we get for some reason
        //     if (change[2] === last[2] && change[1] === last[1]) {
        //         continue
        //     }
        //     // Filter some "noisy" parameters
        //     if (['STAT_FLTTIME', 'STAT_RUNTIME'].includes(change[1])) {
        //         continue
        //     }
        //     last = change
        //     annotationsParams.push(
        //         {
        //             xref: 'x',
        //             yref: 'paper',
        //             x: change[0],
        //             y: 0,
        //             yanchor: 'bottom',
        //             text: change[1] + '->' + change[2].toFixed(4),
        //             showarrow: true,
        //             arrowwidth: 1,
        //             arrowcolor: '#999999',
        //             ay: i,
        //             ax: 0
        //         }
        //     )
        //     i += 23
        //     if (i > 0) {
        //         i = -300
        //     }
        // }
        // updatemenus[0].active = 0
        Plotly.relayout(gd, {
            annotations: [...annotationsModes],
            // updatemenus: updatemenus
        })
        // updatemenus[0].buttons[2].args =
        //     [
        //         'annotations',
        //         [
        //             ...annotationsEvents,
        //             ...annotationsModes,
        //             ...annotationsParams
        //         ]
        //     ]
    }

    const getMode = (time) => {
        for (const mode in modeChanges) {
            if (modeChanges[mode][0] > time) {
                if (mode - 1 < 0) {
                    return modeChanges[0][1]
                }
                return modeChanges[mode - 1][1]
            }
        }
        return modeChanges[modeChanges.length - 1][1]
    }

    const setOfModes = () => {
        const set = []
        for (const mode of modeChanges) {
            if (!set.includes(mode[1])) {
                set.push(mode[1])
            }
        }
        return set
    }

    const generateColorMMap = async () => {
        const colorMapOptions = {
            colormap: 'hsv',
            nshades: Math.max(11, setOfModes(modeChanges)?.length),
            format: 'rgbaString',
            alpha: 1
        }
        const cssColors = colormap(colorMapOptions)
        setCssColorsV(cssColors)
        return
    }

    const getModeColor = (time) => {
        let modeTime = getMode(time)
        let test = setOfModes().indexOf(modeTime)
        let cc = cssColorsV[test]
        return cc
    }

    const addModeShapes = () => {
        const shapes = []
        const modeChanges1 = [...modeChanges]
        modeChanges1.push([gd.layout.xaxis.range[1], null])
        for (let i = 0; i < modeChanges1.length - 1; i++) {
            shapes.push(
                {
                    type: 'rect',
                    // x-reference is assigned to the x-values
                    xref: 'x',
                    // y-reference is assigned to the plot paper [0,1]
                    yref: 'paper',
                    x0: modeChanges1[i][0],
                    y0: 0,
                    x1: modeChanges1[i + 1][0],
                    y1: 1,
                    fillcolor: getModeColor(modeChanges1[i][0] + 1),
                    opacity: 0.15,
                    line: {
                        width: 0
                    }
                }
            )
        }
        Plotly.relayout(gd, { shapes: shapes })
    }

    const darker = (color) => {
        return Color(color).darken(0.2).string()
    }

    const addEvents = () => {
        annotationsEvents = []
        let i = -300
        const modeChanges1 = [...modeChanges]
        modeChanges1.push([gd.layout.xaxis.range[1], null])
        for (let i = 0; i < modeChanges1.length - 1; i++) {
            annotationsModes.push(
                {
                    xref: 'x',
                    yref: 'paper',
                    x: modeChanges1[i][0],
                    xanchor: 'left',
                    y: 0,
                    textangle: 90,
                    text: '<b>' + modeChanges1[i][1] + '</b>',
                    showarrow: false,
                    font: {
                        color: darker(getModeColor(modeChanges1[i][0] + 1))
                    },
                    opacity: 1
                }
            )
        }
        Plotly.relayout(gd, { annotations: annotationsModes })
    }

    const plot = () => {
        if (expressionsLocal.length === 0) {
            return;
        } else {
            const datasets = [];
            for (const expression of expressionsLocal) {
                let data = evaluateExpression(expression.name);
                const mode = data.isSwissCheese ? 'lines+markers' : 'lines';
                const marker = data.isSwissCheese ? {
                    size: 5,
                    symbol: 'cross-thin',
                    color: expression.color,
                    line: {
                        color: expression.color,
                        width: 1
                    }
                } : {
                    size: 4,
                    color: expression.color
                };

                datasets.push({
                    name: expression.name,
                    mode: mode,
                    x: data.x,
                    y: data.y,
                    color: expression.color,
                    show: true,
                    yaxis: 'y' + (expression.axis + 1),
                    line: {
                        color: expression.color,
                        width: 1.5
                    },
                    marker: marker
                });

                const axisname = expression.axis > 0 ? ('yaxis' + (expression.axis + 1)) : 'yaxis';

                if (expression.axis <= 6) {
                    // plotOptions[axisname].title = {
                    //     text: graphName,
                    //     font: {
                    //         color: '#3c3c3c'
                    //     },
                    // };
                    plotOptions[axisname].tickfont.color = '#3c3c3c';
                }
            }
            setDatasets(datasets)
            plotOptions.xaxis = {
                rangeslider: { visible: false },
                domain: calculateXAxisDomain(),
                title: 'Time (Seconds)',
                tickformat: valueformat,
            };

            let modeBarOptions = {
                displayModeBar: false,
                displaylogo: false,
                // modeBarButtonsToAdd: [customCapture, customPan, customZoomIn, customZoomOut, customAutoScale, customResetAxes],
                // modeBarButtonsToRemove: [
                //     'pan',
                //     'zoom2d',
                //     'toImage',
                //     'zoomIn2d',
                //     'zoomOut2d',
                //     'autoScale2d',
                //     'resetScale2d',
                // ],
            }

            if (plotInstance !== null) {
                plotOptions.xaxis.range = gd._fullLayout.xaxis.range;
                Plotly.newPlot(
                    gd,
                    datasets,
                    plotOptions,
                    modeBarOptions
                );
            } else {
                plotInstance = Plotly.newPlot(
                    gd,
                    datasets,
                    plotOptions,
                    modeBarOptions
                );
            }
            setGraphRange({
                xaxis: gd.layout.xaxis.range,
                yaxis: gd.layout.yaxis.range
            })
            gd.on('plotly_relayout', onRangeChanged);
            gd.on('plotly_hover', function (data) {
                const infotext = data.points.map(d => d.x);
            });

            addModeShapes();
            addEvents();
            addParamChanges();

            const bglayer = document.getElementsByClassName('bglayer')[0];
            const rect = bglayer.childNodes[0];
            cursor = document.createElementNS('http://www.w3.org/2000/svg', 'line');
            const x = rect.getAttribute('x');
            const y = rect.getAttribute('y');
            const y2 = parseInt(y) + parseInt(rect.getAttribute('height'));
            cursor.setAttribute('id', 'batata');
            cursor.setAttribute('x1', x);
            cursor.setAttribute('y1', y);
            cursor.setAttribute('x2', x);
            cursor.setAttribute('y2', y2);
            cursor.setAttribute('stroke-width', 1);
            cursor.setAttribute('stroke', 'black');
            bglayer.append(cursor);

            if (panActive) {
                panGraph()
            }
            // xAxisInitialRange = [Math.round(gd.layout.xaxis.range[0]), Math.round(gd.layout.xaxis.range[1])]
            // yAxisInitialRange = [Math.round(gd.layout.yaxis.range[0]), Math.round(gd.layout.yaxis.range[1])]
        }
    };

    const isPlotted = (fieldname) => {
        for (const field of expressionsLocal) {
            if (field.name === fieldname) {
                return true
            }
        }
        return false
    }

    const getFirstFreeColor = () => {
        for (const i of allColorsV) {
            let taken = false
            for (const field of expressionsLocal) {
                if (field.color == i) {
                    taken = true
                }
            }
            if (!taken) {
                return i
            }
        }
        return allColorsV[allColorsV.length - 1]
    }

    const getFirstFreeAxis = () => {
        for (const i of allAxisV) {
            let taken = false
            for (const field of expressionsLocal) {
                if (field.axis == i) {
                    taken = true
                }
            }
            if (!taken) {
                return i
            }
        }
        return allAxisV.length - 1
    }

    const createNewField = (fieldname, axis, color) => {
        let newColor = color
        let newAxis = axis
        if (newColor === undefined) {
            newColor = getFirstFreeColor()
        } else if (!isNaN(newColor)) {
            newColor = allColorsV[color]
        }
        if (newAxis === undefined) {
            newAxis = getFirstFreeAxis()
        }
        return {
            name: fieldname,
            color: newColor,
            axis: newAxis
        }
    }

    const addPlots = async (plots) => {
        const requested = new Set()
        for (const plot of plots) {
            const expression = plot[0]
            if (expression !== null) {
                for (const message of expression) {
                    requested.add(message)
                }
            }
        }
        const newplots = []
        let count = 0
        for (const plot of plots) {
            const expression = plot[0]
            const axis = plot[1]
            const color = count
            if (count >= allColorsV.length - 1) count = 0
            else count += 1

            if (!isPlotted(expression)) {
                newplots.push(createNewField(expression, axis, color))
            }
        }

        expressionsLocal.push(...newplots)
        return
    }

    const initData = async () => {
        await generateColorMMap()
        const gdData = initializeGraph()
        setGd(gdData)
    }

    useEffect(() => {
        initData()
    }, [])

    useEffect(() => {
        if (expressions?.length > 0 && gd) {
            addPlots(expressions).then(() => {
                plot()
            })

        }
    }, [expressions, gd])

    const initializeGraph = () => {
        const WIDTH_IN_PERCENT_OF_PARENT = 90
        d3.select('#line')
            .append('div')
            .style({
                width: '100%',
                'margin-left': (100 - WIDTH_IN_PERCENT_OF_PARENT) / 2 + '%',
                height: '100%'
            })

        let gd = d3.select('#line').node()
        return gd
    }

    const downloadToPng = () => {
        Plotly.toImage(gd, { format: 'png', height: 500, width: 700 }).then(function (dataUrl) {
            const link = document.createElement('a');
            link.href = dataUrl;
            link.download = `${graphName}.png`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        });
    }

    const panGraph = () => {
        const currentMode = gd._fullLayout.dragmode;
        if (currentMode === 'pan') {
            Plotly.relayout(gd, { dragmode: 'zoom' });
            setPanActive(false)
        } else {
            Plotly.relayout(gd, { dragmode: 'pan' });
            setPanActive(true)
        }
    }

    const zoomInGraph = () => {
        Plotly.relayout(gd, {
            'xaxis.range': [
                gd._fullLayout.xaxis.range[0] + 0.1 * (gd._fullLayout.xaxis.range[1] - gd._fullLayout.xaxis.range[0]),
                gd._fullLayout.xaxis.range[1] - 0.1 * (gd._fullLayout.xaxis.range[1] - gd._fullLayout.xaxis.range[0]),
            ],
            'yaxis.range': [
                gd._fullLayout.yaxis.range[0] + 0.1 * (gd._fullLayout.yaxis.range[1] - gd._fullLayout.yaxis.range[0]),
                gd._fullLayout.yaxis.range[1] - 0.1 * (gd._fullLayout.yaxis.range[1] - gd._fullLayout.yaxis.range[0]),
            ],
        })
    }

    const zoomOutGraph = () => {
        Plotly.relayout(gd, {
            'xaxis.range': [
                gd._fullLayout.xaxis.range[0] - 0.1 * (gd._fullLayout.xaxis.range[1] - gd._fullLayout.xaxis.range[0]),
                gd._fullLayout.xaxis.range[1] + 0.1 * (gd._fullLayout.xaxis.range[1] - gd._fullLayout.xaxis.range[0]),
            ],
            'yaxis.range': [
                gd._fullLayout.yaxis.range[0] - 0.1 * (gd._fullLayout.yaxis.range[1] - gd._fullLayout.yaxis.range[0]),
                gd._fullLayout.yaxis.range[1] + 0.1 * (gd._fullLayout.yaxis.range[1] - gd._fullLayout.yaxis.range[0]),
            ],
        });
    }

    const resetGraph = () => {
        Plotly.relayout(gd, {
            'xaxis.autorange': true,
            'yaxis.autorange': true,
        });
    }

    return (<div style={{ width: '100%', height: '100%' }}>
        <div className='graphHeader'>
            <div className='graphTitle'>{graphName?.replace(/,/g, ', ')}</div>
            <div className='modeBarButons'>
                <img title='Download as a png' className='modeBarButonsIcon' src={captureIcon} onClick={() => { downloadToPng() }} />
                <img title='Pan' className='modeBarButonsIcon' src={panActive ? panActiveIcon : panIcon} onClick={() => { panGraph() }} />
                <img title='Zoom in' className='modeBarButonsIcon' src={zoomInIcon} onClick={() => { zoomInGraph() }} />
                <img title='Zoom out' className='modeBarButonsIcon' src={zoomOutIcon} onClick={() => { zoomOutGraph() }} />
                <img title='Auto scale' className='modeBarButonsIcon' src={autoScaleIcon} onClick={() => { resetGraph() }} />
                <img title='Reset axes' className='modeBarButonsIcon' src={resetAxesIcon} onClick={() => { resetGraph() }} />
            </div>
        </div>
        <div id="custom-legend" className='customLegend'>
            {datasets.map((trace, index) => {
                return <React.Fragment key={index}>
                    <div className={trace.show ? 'legendItem' : 'legendItem legendItemDisable'}
                        onClick={() => {
                            const currentVisibility = gd.data[index].visible;
                            const newVisibility = currentVisibility === true || currentVisibility === 'legendonly' ? 'none' : 'legendonly';
                            Plotly.restyle(gd, { visible: newVisibility }, [index]);
                            trace.show = !trace.show
                            setDatasets([...datasets])
                        }}>
                        <span style={{ width: '12px', height: '12px', backgroundColor: trace.color, borderRadius: '50%' }} />
                        <span style={{ marginLeft: '10px', width: '70px', }}>{trace.name}</span>
                        <span style={{ width: '100px', }}>Min: {Math.min(...trace.y).toFixed(2)}</span>
                        <span style={{ width: '100px', }}>Max: {Math.max(...trace.y).toFixed(2)}</span>
                        <span style={{ width: '100px', }}>Mean: {(trace.y.reduce((a, b) => a + b, 0) / trace.y.length).toFixed(2)}</span>
                    </div>
                    {!(index == datasets.length - 1) && < hr style={{ margin: '5px 0px' }} />}
                </React.Fragment>
            })}
        </div>
        <div id="line" className='graphContainer'></div>
    </div>)
}

export default LogAnalyzerPlot
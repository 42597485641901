import React, { useEffect, useState } from 'react'
import { AnimatedModal } from './AnimatedModal'
import * as API from "../api"
import AppConstants from '../AppConstants'
import DropDownWithButton from './DropDownWithButton'
import Loader from "../icons/loader.svg"
import { BUY_MORE_DRONES } from '../Profile/ProfileSettings'
import { displayRazorpay, Popup } from './reusableFunctions'
import { useHistory } from 'react-router-dom'
import AeroGCSLogo from "../../src/icons/AeroGCSEnterprise.svg"
const BuyDrone = (props) => {
    const history = useHistory()
    const [loading, setLoading] = useState(false)
    const [droneBuyObj, setDroneBuyObj] = useState({ id: "", qty: 1, discount_id: "0", plan_type: "Monthly", coupon_id: "", discount: "" })
    const [note, setNote] = useState({ type: "", msg: "" })
    const [droneAddOnPlans, setDroneAddOnPlans] = useState([])
    const [droneURL, setDroneURL] = useState("")

    const getDiscountedPrice = () => {
        return Number(droneBuyObj.plan_cost) - ((Number(droneBuyObj.discount_percentage) / 100) * Number(droneBuyObj.plan_cost))
    }

    const buildDroneBuyURL = () => {
        setDroneURL(prev => {
            let droneBuyURL = ""
            if (props.firstTime) { //this means subscription needs to be created for drone plan
                droneBuyURL = `${process.env.REACT_APP_SERVER_PROTOCOL}://${process.env.REACT_APP_SERVER_HOST}${process.env.REACT_APP_SERVER_PORT}/makePayment/${droneBuyObj?.id}/${droneBuyObj?.price_id}?email=${props.user.username}&quantity=${droneBuyObj?.qty ? droneBuyObj?.qty : 1}&user_sub_id=${droneBuyObj.user_sub_id || ''}&cancelURL=${`${process.env.REACT_APP_CLIENT_PROTOCOL}://${process.env.REACT_APP_CLIENT_HOST}${process.env.REACT_APP_CLIENT_PORT}/profile-settings`}`
            } else { //this means drone plan already bought (subscription needs to be updated,already exist)
                droneBuyURL = `${process.env.REACT_APP_SERVER_PROTOCOL}://${process.env.REACT_APP_SERVER_HOST}${process.env.REACT_APP_SERVER_PORT}/updateDroneSub/${droneBuyObj?.id}/${droneBuyObj?.price_id}?email=${props.user.username}&quantity=${droneBuyObj?.qty ? droneBuyObj.qty : 1}`
            }
            return droneBuyURL
        })
    }


    const handleCheckout = async () => {
        Popup.alert("WAIT", "Redirecting to payment, please wait...")
        try {
            const planAmount = Number(droneBuyObj.plan_cost) * Number(droneBuyObj.qty)
            console.log('planAmount', planAmount)
            const data = await API.createOrderForRenew(planAmount,  droneBuyObj);
            if (data.success) {
                Popup.close()
                displayRazorpay({
                    key: process.env.REACT_APP_RAZORPAY_KEY,
                    amount: data.order.amount,
                    order_id: data.order.id,
                    currency: "INR",
                    name: "AeroGCS",
                    image: AeroGCSLogo,
                    handler: async (response) => {
                        const res = await API.verifyRazorPayPayment(response)
                        if (res.success) {
                            history.push(`/payment/success?reference=${res.payment_id}`, {
                                message: "Congratulations, your drone add on subscription will be activated in few minutes..."
                            })
                        } else {
                            history.push(`/payment/failed?reference=${res.payment_id}`, {
                                message: "Something went wrong while processing subscription."
                            })
                        }
                    },
                    notes: {
                        payment_type: "first_drone_payment",
                        db_subscription_plan_id: droneBuyObj.id,
                        quantity: droneBuyObj.qty,
                    },
                    prefill: {
                        email: props.user.username,
                    },
                    readonly: { email: true },
                    theme: {
                        "color": "#2989cf"
                    },
                    method: {
                        upi: false, 
                    },
                })
            }
        } catch (e) {
            Popup.alert("ERROR", e.message)
        }
    }

    const getAeromeghPlans = () => {
        API.getAeromeghPlans().then(res => {
            if (res.plans.length > 0) {
                const droneAddonList = res.plans.filter(pl => pl.service === AppConstants.SERVICES.DRONE_CONTROL_CENTER)

                setDroneAddOnPlans(droneAddonList)
                let monthlyDronePlan = undefined
                if (props.firstTime) {
                    monthlyDronePlan = droneAddonList.find(pl => pl.plan_type === "Monthly") //set selected drone plan as monthly by default
                }

                setDroneBuyObj(prev => ({ ...prev, ...monthlyDronePlan }))
                API.getUserSubscriptionPlan().then(sub => {

                    // first check which aerogcs plan is active
                    // find active user drone add on if already exist
                    for (const drone of droneAddonList) {
                        for (const userPlan of sub.plans) {
                            if (drone.id === userPlan.plan_id && userPlan.service_name === AppConstants.SERVICES.DRONE_CONTROL_CENTER && userPlan.subscription_status === "Active") //this is active user drone add on
                            {
                                setDroneBuyObj(prev => ({ ...prev, ...drone, ...userPlan }))
                            }
                        }
                    }
                    const activeUserAeroGCSPLan = sub.plans.filter(pl => pl.service_name === AppConstants.SERVICES.AEROGCS && (pl.subscription_status).toLowerCase() === "active" && pl.plan_type != "Trial")

                    if (activeUserAeroGCSPLan.length > 0) {
                        // there is active gcs plan
                        const subscriptionPlan = res.plans.find(pl => pl.id === activeUserAeroGCSPLan[0].plan_id) //get the active aerogcs plan to get the coupon
                        API.getUserSubscriptionDiscount(subscriptionPlan.drone_addon_discount_coupon).then(res => {
                            setDroneBuyObj(prev => ({ ...prev, discount_id: res.discount[0]?.coupon_id, discount_percentage: res.discount[0]?.discount, user_sub_id: subscriptionPlan.id, }))
                        })
                    } else {
                        // there is no gcs plan active
                        setNote({ type: "Attention", msg: "No Active AeroGCS plan found." })
                    }
                    //take its discount and send to droneobjurl
                    setLoading(false)
                }).catch(err => {
                    console.log(err)
                    setLoading(false)
                })



            } else {
                console.log("No plans found")
                setLoading(false)
            }

        }).catch(e => {
            console.log(e)
            setLoading(false)
            // window.location.replace(process.env.REACT_APP_CLIENT_PROTOCOL + "://" + process.env.REACT_APP_CLIENT_HOST + (process.env.REACT_APP_CLIENT_PORT ? process.env.REACT_APP_CLIENT_PORT : ""))
        })



    }
    useEffect(() => {
        buildDroneBuyURL()
    }, [droneBuyObj])

    useEffect(() => {
        setLoading(true)
        if (!props.firstTime) {
            setNote({ type: "Note", msg: "Amount for additional drones bought will be charged from user immediately." })
        }
        getAeromeghPlans()
        buildDroneBuyURL()
        // if (props.pendingPayments?.length > 0) setNote({ type: "Attention", msg: "Payments are due,you cannot buy more drones" })
    }, [])

    useEffect(() => {
        const discountPrice = (Number(droneBuyObj.plan_cost)) * ((Number(droneBuyObj.discount_percentage) || 0) / 100)

        const totalPrice = ((Number(droneBuyObj.plan_cost)) - Number(discountPrice)) * (Number(droneBuyObj.qty))

        if (totalPrice > 999999.99) {
            setNote({ type: "Attention", msg: <span>You cannot pay more than ₹<span style={{ color: "rgb(244, 67, 54)", fontWeight: "500" }}>999,999.99</span> in a single transaction</span> })
        } else {
            setNote({ type: "Attention", msg: "You will be charged instantly for the additional drones, which will cover the remaining days of your current Add-on subscription." })
        }
    }, [droneBuyObj.qty, droneBuyObj.plan_type])

    return (
        <>

            <AnimatedModal
                isOpen={props.show}
                onRequestClose={props.onRequestClose}
                height={"auto"}
            >
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        handleCheckout()
                    }}
                    style={{
                        boxShadow: "0px 0px 0px 1px rgb(235,238,241)",
                        background: "white",
                        height: "",
                        width: "700px",
                        marginTop: "",
                        overflow: "hidden",
                        borderRadius: "10px",
                        display: "flex",
                        flexDirection: "column"
                    }}>
                    <div style={{ backgroundColor: "#EAEAEA", height: "40px", display: "flex", justifyContent: "center", alignItems: "center", font: "15px", color: "#3c3c3c", fontWeight: "500" }}> AEROGCS ADD ON DRONES </div>

                    {loading ?
                        <div style={{ height: "100%", minWidth: "200px", width: "100%", display: "flex", textAlign: "center", display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <img src={Loader} style={{ height: "100%", width: "200px", margin: "auto", paddingLeft: "50px" }} />
                        </div> :
                        <>
                            <div style={{ padding: "20px 100px 5px 100px", borderBottom: "", textAlign: "left", position: "relative", display: "flex", flexDirection: "column", gap: "10px", height: "100%" }}>
                                {/* {props.firstTime && */}
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "right" }}>
                                    <DropDownWithButton
                                        list={[{
                                            name: "Monthly", onClick: () => {
                                                const selctedDronPlan = droneAddOnPlans.find(pl => pl.plan_type === "Monthly")
                                                setDroneBuyObj(prev => ({ ...prev, ...selctedDronPlan }))
                                                buildDroneBuyURL()
                                            }
                                        }, {
                                            name: "Yearly", onClick: () => {
                                                const selctedDronPlan = droneAddOnPlans.find(pl => pl.plan_type === "Yearly")
                                                setDroneBuyObj(prev => ({ ...prev, ...selctedDronPlan }))
                                                buildDroneBuyURL()
                                            }
                                        }]}
                                        selectedItem={droneBuyObj.plan_type}
                                    />
                                </div>
                                {/* } */}

                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div style={{ width: '70%', }}>
                                        <div style={{ borderTop: "1px solid lightgray", borderBottom: "1px solid lightgray" }} >
                                            <div style={{ color: "rgb(65,69,82)", fontWeight: "700", padding: "10px 0px", textAlign: "left" }}>Product</div>
                                        </div>
                                        <div style={{ color: "rgb(65,69,82)", fontWeight: "500", padding: "10px 0px", textAlign: "left" }}>
                                            <div style={{ fontSize: "13px", fontWeight: "500" }}>{(!loading && droneBuyObj?.plan_name) || "--"}</div>
                                            <div style={{ fontSize: "13px", fontWeight: "400", color: "#687385" }}>{`₹${(!loading && (droneBuyObj?.plan_cost === undefined ? "--" : droneBuyObj?.plan_cost))}/${loading ? "---" : droneBuyObj?.plan_type === "Yearly" ? "year" : "month"}`}</div>
                                            {/* <div style={{ fontSize: "13px", fontWeight: "400", color: "#687385" }}>{`Discount ${(!loading && droneBuyObj?.discount_percentage) || "--"}%`}</div> */}
                                            {/* {droneBuyObj?.discount_percentage && <div style={{ fontSize: "13px", fontWeight: "400", color: "#687385" }}> */}
                                            {/* {`Discounted Price - (${(!loading && droneBuyObj?.discount_percentage) || "--"}%)`} */}
                                            {/* <span style={{ color: "#2989cf", fontWeight: "500" }}>{`₹${("" + getDiscountedPrice()) || "--"}/${droneBuyObj?.plan_type === "Yearly" ? "year" : "month"}` || "--"}</span></div>} */}
                                        </div>
                                    </div>
                                    <div style={{ width: '30%', }}>
                                        <div style={{ borderTop: "1px solid lightgray", borderBottom: "1px solid lightgray" }} >
                                            <div style={{ color: "rgb(65,69,82)", fontWeight: "700", padding: "10px 0px", textAlign: "right" }}>Qty</div>
                                        </div>
                                        <div style={{ color: "rgb(65,69,82)", fontWeight: "500", padding: "10px 0px", textAlign: "right", display: "flex", alignItems: 'flex-end', justifyContent: "right" }}>
                                            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                                                <div><input type="text" style={{ transition: "0.5s all", width: "45px", padding: "5px", borderRadius: "5px", outline: "none", border: (droneBuyObj.qty == 0 || droneBuyObj.qty === "") && droneBuyObj?.id ? "1px solid red" : "1px solid lightgray", textAlign: "center" }}
                                                    value={droneBuyObj.qty}
                                                    onChange={(e) => {
                                                        let val = e.target.value.replace(/[^0-9]/g, '')
                                                        if (!isNaN(val) && val.length <= 3 && Number(val) <= 100) {
                                                            setDroneBuyObj(prev => ({ ...prev, qty: Number(val), }))
                                                            buildDroneBuyURL()
                                                        }
                                                    }}
                                                />
                                                </div>
                                                {/* <div style={{ fontSize: "12px" }}>{`Current user drones(${this.state.prevDroneQuantity}) + Additional drones(${droneBuyObj.qty}) = ${Number(this.state.prevDroneQuantity) + Number(droneBuyObj.qty)}`}</div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {note.type && <div style={{ fontSize: "12px", color: "red" }}>
                                    <span style={{ fontWeight: "500", color: note.type === "Attention" ? "#f44336" : "#2989cf", }}>{note.type}: </span>
                                    <span style={{ color: "#687385" }}>{note.msg}</span>
                                </div>}
                            </div>
                            <div style={{ display: "flex", justifyContent: "space-between", width: "100%", padding: " 10px 100px" }}>
                                <div
                                    style={{ width: "100%" }}
                                >
                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>

                                        {/* {droneBuyObj.qty > 0 && props.pendingPayments.length > 0 && <div style={{ color: "red", fontSize: "12px", marginRight: "10px" }}>Payments are due,you cannot buy more drones.</div>} */}
                                        {/* {(droneBuyObj.qty == 0 || droneBuyObj.qty === "") && <div style={{ color: "red", fontSize: "12px", marginRight: "10px" }}>Please enter a valid quantity.</div>} */}
                                        {/* {!droneBuyObj && <div style={{ color: "red", fontSize: "12px", marginRight: "10px" }}>Please buy any of the AeroGCS plans to continue.</div>} */}
                                        <button className='remove-botton-styling' style={{
                                            transition: "0.5s all",
                                            fontSize: "11px",
                                            height: "30px",
                                            color: "#2989cf",
                                            backgroundColor: "white",
                                            border: "1px solid #2989cf",
                                            borderRadius: "20px",
                                            padding: "6px 20px 10px",
                                            fontWeight: "600",
                                        }} type="button" onClick={props.onRequestClose}>Back</button>
                                        {(loading || droneBuyObj.qty <= 0) ? <button className='remove-botton-styling' style={{
                                            transition: "0.5s all",
                                            fontSize: "11px",
                                            height: "30px",
                                            color: "white",
                                            backgroundColor: "#2989cf",
                                            borderRadius: "20px",
                                            padding: "6px 20px 10px",
                                            fontWeight: "600",
                                            opacity: "0.5",
                                            cursor: "not-allowed"
                                        }} type="button" >Buy</button>
                                            : <button className='remove-botton-styling' style={{
                                                transition: "0.5s all",
                                                fontSize: "11px",
                                                height: "30px",
                                                color: "white",
                                                backgroundColor: "#2989cf",
                                                borderRadius: "20px",
                                                padding: "6px 20px 10px",
                                                fontWeight: "600",
                                                opacity: "1",
                                                cursor: "pointer"
                                            }} type="submit" >Buy</button>}
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                </form>
            </AnimatedModal>
        </>
    )
}

export default BuyDrone
import React, { useEffect, useState } from 'react';
import TableView from '../../ReusableComponents/TableView/TableView.js';
import * as API from '../../api.js';
import { BreadCrumbs } from '../../ReusableComponents/BreadCrumbs.js';
import { useHistory } from "react-router-dom"
import { GCS_FEATURES, getActiveTeam, getFeaturesPermissions } from '../../Teams/index.js';
import { addUserAnalytic, convertMode, Popup, updateOutTime } from '../../ReusableComponents/reusableFunctions.js';
import Cookies from "universal-cookie"
import { v4 as uuid } from 'uuid';
import LogAnalyzerPlot from './LogAnalyzerPlot.js';
import LoaderComponent from '../../ReusableComponents/LoaderComponent.js';
import ArrowIcon from '../../icons/logMenuArrow.svg'
import ActiveArrowIcon from '../../icons/logMenuArrowActive.svg'

import AltitudeIcon from '../../icons/logAltitude.svg'
import AltitudeActiveIcon from '../../icons/logAltitudeActive.svg'
import SpeedIcon from '../../icons/logSpeed.svg'
import SpeedActiveIcon from '../../icons/logSpeedActive.svg'
import SatelliteIcon from '../../icons/logSattelite.svg'
import SatelliteActiveIcon from '../../icons/logSatteliteActive.svg'
import BatteryIcon from '../../icons/logBattery.svg'
import BatteryActiveIcon from '../../icons/logBatteryActive.svg'
import ServosIcon from '../../icons/logServos.svg'
import ServosActiveIcon from '../../icons/logServosActive.svg'
import AttitudeIcon from '../../icons/logAttitude.svg'
import AttitudeActiveIcon from '../../icons/logAttitudeActive.svg'
import SensorsIcon from '../../icons/logSensors.svg'
import SensorsActiveIcon from '../../icons/logSensorsActive.svg'
import RCChannelIcon from '../../icons/logRCChannels.svg'
import RCChannelActiveIcon from '../../icons/logRCChannelsActive.svg'
import FlowRateIcon from '../../icons/logFlowRate.svg'
import FlowRateActiveIcon from '../../icons/logFlowRateActive.svg'
import liquidConsumedIcon from '../../icons/logLiquidConsumed.svg'
import liquidConsumedActiveIcon from '../../icons/logLiquidConsumedActive.svg'
import liquidRemainingIcon from '../../icons/logLiquidRemaining.svg'
import liquidRemainingActiveIcon from '../../icons/logLiquidRemainingActive.svg'


const LogAnalyzer = (props) => {
    const [loading, setLoading] = useState(true)
    const [user, setUser] = useState({})
    const [outId, setOutId] = useState("")
    const [activeTeam, setActiveTeam] = useState('')
    const [flightLogs, setFlightLogs] = useState([])
    const [modeChanges, setModeChanges] = useState([])
    const [timeScale, setTimeScale] = useState([])
    const [openMenus, setOpenMenus] = useState([])
    const [permissions, setPermissions] = useState([])
    const [selectedExpressions, setSelectedExpressions] = useState([])
    const [selectedGraph, setSelectedGraph] = useState({})
    const [graphList, setGraphList] = useState([
        {
            name: 'Speed',
            icon: SpeedIcon,
            activeIcon: SpeedActiveIcon,
            expression: [['vel', 0]],
            inputCheck: true,
            checked: true,
        },
        {
            name: 'GPS',
            icon: SatelliteIcon,
            activeIcon: SatelliteActiveIcon,
            childrens: [
                {
                    name: 'Altitude',
                    // icon: AltitudeIcon,
                    // activeIcon: AltitudeActiveIcon,
                    expression: [['alt', 0]],
                    inputCheck: true,
                    checked: false,

                },
                {
                    name: 'Satellites',
                    expression: [['sat', 0]],
                    inputCheck: true,
                    checked: false,
                },
                {
                    name: 'VDOP',
                    expression: [['vdop', 0]],
                    inputCheck: true,
                    checked: false,
                },
                {
                    name: 'HDOP',
                    expression: [['hdop', 0]],
                    inputCheck: true,
                    checked: false,
                },

            ]
        },
        {
            name: 'Battery',
            icon: BatteryIcon,
            activeIcon: BatteryActiveIcon,
            childrens: [
                {
                    name: 'Battery Voltage',
                    expression: [['bat', 0]],
                    inputCheck: true,
                    checked: false,
                },
                {
                    name: 'Battery Remaining Percentage',
                    expression: [['batRem', 0]],
                    inputCheck: true,
                    checked: false,
                },
            ]
        },
        {
            name: 'Attitude',
            icon: AttitudeIcon,
            activeIcon: AttitudeActiveIcon,
            childrens: [
                {
                    name: 'Roll',
                    expression: [['rol', 0]],
                    inputCheck: true,
                    checked: false,
                },
                {
                    name: 'Pitch',
                    expression: [['pit', 0]],
                    inputCheck: true,
                    checked: false,
                },
                {
                    name: 'Yaw',
                    expression: [['yaw', 0]],
                    inputCheck: true,
                    checked: false,
                },
            ]
        },
        {
            name: 'Sensors',
            icon: SensorsIcon,
            activeIcon: SensorsActiveIcon,
            childrens: [
                {
                    name: 'Accelerometer',
                    childrens: [
                        {
                            name: 'Accelerometers',
                            expression: [['xacc', 0], ['yacc', 0], ['zacc', 0]],
                            inputCheck: true,
                            checked: false,
                        },
                        {
                            name: 'Vibration',
                            expression: [['vibx', 0], ['viby', 0], ['vibz', 0]],
                            inputCheck: true,
                            checked: false,
                        },
                    ]
                },
                {
                    name: 'Gyroscope',
                    expression: [['xgyro', 0], ['ygyro', 0], ['zgyro', 0]],
                    inputCheck: true,
                    checked: false,
                },
                {
                    name: 'Compass',
                    expression: [['xmag', 0], ['ymag', 0], ['zmag', 0]],
                    inputCheck: true,
                    checked: false,
                },
            ]
        },
        {
            name: 'Servos',
            icon: ServosIcon,
            activeIcon: ServosActiveIcon,
            childrens: [
                {
                    name: 'Servo 1',
                    expression: [['servo1', 0]],
                    inputCheck: true,
                    checked: false,
                },
                {
                    name: 'Servo 2',
                    expression: [['servo2', 0]],
                    inputCheck: true,
                    checked: false,
                },
                {
                    name: 'Servo 3',
                    expression: [['servo3', 0]],
                    inputCheck: true,
                    checked: false,
                },
                {
                    name: 'Servo 4',
                    expression: [['servo4', 0]],
                    inputCheck: true,
                    checked: false,
                },
                {
                    name: 'Servo 5',
                    expression: [['servo5', 0]],
                    inputCheck: true,
                    checked: false,
                },
                {
                    name: 'Servo 6',
                    expression: [['servo6', 0]],
                    inputCheck: true,
                    checked: false,
                },
                {
                    name: 'Servo 7',
                    expression: [['servo7', 0]],
                    inputCheck: true,
                    checked: false,
                },
                {
                    name: 'Servo 8',
                    expression: [['servo8', 0]],
                    inputCheck: true,
                    checked: false,
                },
            ]

        },
        {
            name: 'RC Channels',
            icon: RCChannelIcon,
            activeIcon: RCChannelActiveIcon,
            childrens: [
                {
                    name: 'RC Input 1',
                    expression: [['rc1min', 0], ['rc1mid', 0], ['rc1max', 0]],
                    inputCheck: true,
                    checked: false,
                },
                {
                    name: 'RC Input 2',
                    expression: [['rc2min', 0], ['rc2mid', 0], ['rc2max', 0]],
                    inputCheck: true,
                    checked: false,
                },
                {
                    name: 'RC Input 3',
                    expression: [['rc3min', 0], ['rc3mid', 0], ['rc3max', 0]],
                    inputCheck: true,
                    checked: false,
                },
                {
                    name: 'RC Input 4',
                    expression: [['rc4min', 0], ['rc4mid', 0], ['rc4max', 0]],
                    inputCheck: true,
                    checked: false,
                },
                {
                    name: 'RC Input 5',
                    expression: [['rc5min', 0], ['rc5mid', 0], ['rc5max', 0]],
                    inputCheck: true,
                    checked: false,
                },
                {
                    name: 'RC Input 6',
                    expression: [['rc6min', 0], ['rc6mid', 0], ['rc6max', 0]],
                    inputCheck: true,
                    checked: false,
                },
                {
                    name: 'RC Input 7',
                    expression: [['rc7min', 0], ['rc7mid', 0], ['rc7max', 0]],
                    inputCheck: true,
                    checked: false,
                },
                {
                    name: 'RC Input 8',
                    expression: [['rc8min', 0], ['rc8mid', 0], ['rc8max', 0]],
                    inputCheck: true,
                    checked: false,
                },
            ]
        },
        {
            name: 'Flow Rate',
            icon: FlowRateIcon,
            activeIcon: FlowRateActiveIcon,
            expression: [['flowRate', 0]],
            inputCheck: true,
            checked: false,
        },
        {
            name: 'Liquid Consumed',
            icon: liquidConsumedIcon,
            activeIcon: liquidConsumedActiveIcon,
            expression: [['liqCon', 0]],
            inputCheck: true,
            checked: false,
        },
        {
            name: 'Remaining Liquid Percentage',
            icon: liquidRemainingIcon,
            activeIcon: liquidRemainingActiveIcon,
            expression: [['liqRem', 0]],
            inputCheck: true,
            checked: false,
        },
    ])

    useEffect(() => {
        document.title = "Log Analyzer";
        try {
            setSelectedGraph(graphList[0])
            setSelectedExpressions(graphList[0].expression)
            if (props.location.state.user) {
                setUser(props.location.state.user);
                getTeamsPermissions()
            } else {
                getUser()
            }
        } catch (e) {
            console.log(e);
            Popup.alert("ERROR", e.message)
        }
    }, []);

    useEffect(() => { return () => { updateOutTime(outId) } }, [outId])

    const getUser = async () => {
        const [blah, type, user] = await API.getUserType();
        setUser(user);
        getTeamsPermissions()
    }

    const getTeamsPermissions = () => {
        addUserAnalytic(props.location.pathname).then(id => { setOutId(id) })
        getActiveTeam().then((activeTeam) => {
            setActiveTeam(activeTeam)
            getFeaturesPermissions([GCS_FEATURES.PLANS]).then(async (permissions) => {
                setPermissions(permissions)
                await getFlightLogs()
                setLoading(false)
            })
        })
    }

    const getFlightLogs = async () => {
        return new Promise(async (resolve, reject) => {
            let flightDetails = await API.log(props.location.state.flightId, 'AS_TELE_DATA', 0, 0)
            const modeChanges = []
            let previousMode = null
            const firstLogTimeStamp = flightDetails?.logs?.[0]?.timestamp ? new Date(flightDetails?.logs?.[0]?.timestamp).getTime() / 1000 : 0
            const timeScale = []
            const logsData = []
            let count = 0
            for (const l of flightDetails.logs) {
                const logTime = new Date(l.timestamp).getTime() / 1000
                const time = (logTime - firstLogTimeStamp)
                timeScale.push(time)
                const jsonData = JSON.parse(l.data)
                logsData.push({ ...jsonData, batRem: jsonData.batteryRemaining, liqCon: jsonData.liquidConsumed, liqRem: jsonData.remainingLiquidPercentage, timeFromStart: time })
                const { mod } = jsonData
                if (mod !== previousMode) {
                    modeChanges.push([time, convertMode(mod)]);
                    previousMode = mod;
                }
                count++
            }
            if (count == flightDetails.logs.length) {
                setFlightLogs(logsData)
                setTimeScale(timeScale)
                setModeChanges(modeChanges)
                resolve()
            }
        })
    }

    const toggleDropdown = (index) => {
        if (openMenus.includes(index)) {
            setOpenMenus(openMenus.filter((menuIndex) => menuIndex !== index))
        } else {
            setOpenMenus([...openMenus, index])
        }
    };

    const renderMenu = (items = graphList, level = 0) => {
        return <ul className='logAnalyzerMenuList'>
            {items.map((item, index) => {
                return <>
                    <li key={level + index} className='logAnalyzerMenuListItem'
                        style={{
                            marginLeft: level == 1 ? item.inputCheck || item.childrens ? '34px' : '68px' : level == 2 ? '66px' : '0px',
                            backgroundColor: openMenus.includes(`${index}-${item.name}`) ? '#3988F91A' : '',
                            opacity: item.childrens || (item.expression && item.expression.every(([key]) => key in flightLogs[0])) ? 1 : 0.5,
                            cursor: item.childrens || (item.expression && item.expression.every(([key]) => key in flightLogs[0])) ? 'pointer' : 'not-allowed',
                        }}
                        onClick={() => {
                            if (item.childrens) {
                                toggleDropdown(`${index}-${item.name}`)
                            } else if (item.expression.every(([key]) => key in flightLogs[0])) {
                                if (item.inputCheck && selectedGraph.inputCheck) {
                                    
                                    if (item.checked) {
                                        item.checked = false
                                        setGraphList([...graphList])
                                        let newExpressions = selectedExpressions.filter((expression) => !item.expression.includes(expression))
                                        if (newExpressions.length > 0) {
                                            setSelectedExpressions(newExpressions)
                                            let name = selectedGraph.name.split(',').filter((name) => name != item.name).join(',')
                                            setSelectedGraph({ ...selectedGraph, name })
                                        } else {
                                            graphList[0].checked = true
                                            setGraphList([...graphList])
                                            setSelectedGraph({ ...graphList[0], checked: true })
                                            setSelectedExpressions(graphList[0].expression)
                                        }
                                    } else {
                                        item.checked = true
                                        setGraphList([...graphList])
                                        let newExpressions = selectedExpressions.concat(item.expression)
                                        setSelectedExpressions(newExpressions)
                                        if (selectedGraph) {
                                            let name = selectedGraph.name ? selectedGraph.name + ',' + item.name : item.name
                                            setSelectedGraph({ ...selectedGraph, name })
                                        } else {
                                            setSelectedGraph(item)
                                        }
                                    }
                                }
                            }
                        }}>
                        {item.childrens && <img src={openMenus.includes(`${index}-${item.name}`) ? ActiveArrowIcon : ArrowIcon}
                            style={{ padding: '5px' }} className='menuIcon' />}
                        {item.inputCheck && <div className='menuIcon logInputDiv'><input className='logInputCheck' checked={item.checked} type='checkbox' /></div>}
                        {item.icon && item.activeIcon && <img src={item.inputCheck ? (item.checked ? item.activeIcon : item.icon) : selectedGraph.name == item.name || openMenus.includes(`${index}-${item.name}`) ? item.activeIcon : item.icon} className='menuIcon' />}
                        <span style={{ color: item.inputCheck ? (item.checked ? '#3988F9' : '') : selectedGraph.name == item.name || openMenus.includes(`${index}-${item.name}`) ? '#3988F9' : '' }}>{item.name}</span>
                    </li>
                    {item.childrens && openMenus.includes(`${index}-${item.name}`) && renderMenu(item.childrens, level + 1)}
                </>
            })}
        </ul >
    }

    return (<div className="wrapper">
        <div className="right-content-new">
            <div className="top-bar-new">
                <div className="top-bar-text-new">
                    <BreadCrumbs
                        data={[
                            {
                                displayName: "AeroGCS",
                                pathname: "/",
                                state: {
                                    user
                                }
                            },
                            {
                                displayName: "Flights",
                                pathname: activeTeam?.isOwner ? "/aerogcsenterprise/flights" : "/drone_management/drone_details",
                                state: {
                                    user,
                                    drone: props?.location?.state?.drone || ''
                                }
                            },
                            {
                                displayName: 'Flight Details',
                                pathname: '/remote_mission/flight_details',
                                state: {
                                    ...props.location.state
                                }
                            },
                            {
                                displayName: 'Log Analyzer'
                            }
                        ]} />
                </div>
            </div>
            {loading || flightLogs.length == 0 ? <LoaderComponent />
                : <div className="main-content-new">
                    <div className='logAnalyzer'>
                        <div className='logAnalyzerMenu'>
                            <div className='logAnalyzerMenuConstainer'>
                                {renderMenu()}
                            </div>
                        </div>
                        <div className='logAnalyzerGraph'>
                            <LogAnalyzerPlot graphName={selectedGraph.name} logData={flightLogs} modeChanges={modeChanges} timeScale={timeScale} expressions={selectedExpressions} />
                        </div>
                    </div>
                </div>}
        </div>
    </div >)
}

export default LogAnalyzer;
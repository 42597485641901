import { Link } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { isServiceAeroGCS } from './reusableFunctions'
import AeroMeghLogo from "../aeromegh-logo-new.png";
import GREEN from '../assets/sideBarIcons/green-icon.png'
import { sideBarPaths } from '../sideBarPaths';
import Help from "../sidebaricon/Help-new.png"
import HelpInactive from "../sidebaricon/Help-Inactive.png"
import Apps from "../sidebaricon/Apps.png"
import AppsInactive from "../sidebaricon/Apps-Inactive.png"
import * as API from '../api'
import { useSelector } from 'react-redux';
import Tippy from '@tippyjs/react';
// import 'tippy.js/dist/tippy.css'; // optional
import CloseIcon from "../icons/blackClose.png"
import { OEM_USERS } from '../AppConstants';
// import 'tippy.js/dist/tippy.css';
const SideBarComponent = (props) => {
    const location = useLocation()
    const sideBarState = useSelector(state => state.sidebar)
    const [route, setRoute] = useState(sideBarPaths[location.pathname])
    const [user, setUser] = useState();
    const [type, setType] = useState()
    const [openApps, setOpenApps] = useState(false)

    useEffect(() => {
        if (location.pathname) {
            setRoute(sideBarPaths[location.pathname])
        }
    }, [location.pathname])

    useEffect(() => {
        if (!user)
            API.getUserType().then(([blah, type, user]) => {
                setUser(user);
                setType(type)
            })
    }, [])

    const [apps, setApps] = useState([
        {
            backgroundColor: '#238E28',
            icon: GREEN,
            text: "Green",
            redirectTo: `/aerogcsenterprise/green_dashboard`,
        },
    ])

    const isToolSelected = (item) => {
        if (!item) return false
        return route.selectedPath === item.path
    }

    const toolJSX = (item) => {
        return item.name == "hr" ? <hr className="sidebar-expanded-separator" />
            : (
                // className={item.selected ? selectBorder : "nonSelectBorder"}
                <div style={{ borderLeft: `solid 2px transparent` }}>
                    <li>
                        {item?.onClick ?
                            <div className="sidecontent-new" id="sidecontent" onClick={item.onClick}>
                                <div className="sidebar-icon-new">
                                    <img
                                        className={`sidebar-icon-img-new  ${item.className}`}
                                        src={isToolSelected(item) ? item.icon.active : item.icon.inActive}
                                        alt="serviceIcon"
                                    />
                                </div>
                                {isToolSelected(item) ? (
                                    <div
                                        name="service"
                                        className={"sidebaropt-new"}
                                        style={{ color: "#FFFFFF", fontWeight: 500 }}
                                    >
                                        {item.name}
                                    </div>
                                ) : (
                                    <div style={{ color: '#FFFFFF99' }}
                                        name="service"
                                        className={
                                            "sidebaropt-new"
                                        }
                                    >
                                        {item.name}
                                    </div>
                                )}
                            </div>
                            :
                            <Link
                                to={{
                                    pathname: item.path,
                                    state: {
                                        config: { user, type },
                                        user,
                                        type,
                                        ...sideBarState
                                        // plan, project
                                    },
                                }}
                            >
                                <div className="sidecontent-new" id="sidecontent">
                                    <div className="sidebar-icon-new">
                                        <img
                                            className={`sidebar-icon-img-new ${item.className}`}
                                            src={isToolSelected(item) ? item.icon.active : item.icon.inActive}
                                            alt="serviceIcon"
                                        />
                                    </div>
                                    {isToolSelected(item) ? (
                                        <div
                                            name="service"
                                            className={

                                                "sidebaropt-new"
                                            }
                                            style={{ color: "#FFFFFF", fontWeight: 500 }}
                                        >
                                            {item.name}
                                        </div>
                                    ) : (
                                        <div style={{ color: '#FFFFFF99' }}
                                            name="service"
                                            className={
                                                "sidebaropt-new"
                                            }
                                        >
                                            {item.name}
                                        </div>
                                    )}
                                </div>
                            </Link>
                        }

                    </li>
                </div>
            )
    }

    return isServiceAeroGCS(location.pathname, props.adminService) && route ?
        <div
            className="sidebar-expanded-new"
            style={{ paddingTop: "0px", backgroundColor: 'white', position: 'absolute', left: '0', top: 0, zIndex: 99 }}
        >
            <div style={{ display: "flex", height: "40px" }}>
                <div style={{ height: "40px", transition: "0.3s", margin: 'auto' }}>
                    <img className='aeromegh-icon' src={AeroMeghLogo} />
                </div>
            </div>
            <ul className="sidebar-nav-new" id="sidebar-nav-new">
                <div>
                    {route?.tools?.map((item) => {
                        if (item.path == '/adminForOEMUser') {
                            if (OEM_USERS.includes(user?.username)) {
                                return toolJSX(item)
                            }
                        } else {
                            return toolJSX(item)
                        }
                    })}

                    {/* HELP TOOL */}
                    {
                        // (route.aeroGCSHelp || route.droneNakshaHelp || route.picStorkHelp || route.help) &&
                        toolJSX({
                            name: 'Help',
                            path: "/help",
                            icon: {
                                active: Help,
                                inActive: HelpInactive
                            },
                            onClick: () => {
                                const url = `https://aerogcs-docs.aeromegh.com/aerogcs-kea-enterprise`
                                window.open(url, "_blank");
                            }
                        })
                    }

                    {/* APPS */}
                    < div
                        style={{ borderLeft: `solid 2px transparent` }}
                    // className={item.selected ? selectBorder : "nonSelectBorder"}
                    >
                        <li>
                            <Tippy visible={openApps} interactive trigger='click' onClickOutside={() => setOpenApps(false)} className='apps-container' placement='right' content={<AppsContainer apps={apps} onClose={() => setOpenApps(false)} />}>
                                <div className="sidecontent-new" id="sidecontent" onClick={() => setOpenApps(prev => !prev)}>
                                    <div className="sidebar-icon-new">
                                        <img
                                            className="sidebar-icon-img-new"
                                            src={openApps ? Apps : AppsInactive}
                                            alt="serviceIcon"
                                        />
                                    </div>
                                    {openApps ? (
                                        <div
                                            name="service"
                                            className={

                                                "sidebaropt-new"
                                            }
                                            style={{ color: "#FFFFFF", fontWeight: 500 }}
                                        >
                                            Apps
                                        </div>
                                    ) : (
                                        <div style={{ color: '#FFFFFF99' }}
                                            name="service"
                                            className={
                                                "sidebaropt-new"
                                            }
                                        >
                                            Apps
                                        </div>
                                    )}
                                </div>
                            </Tippy>

                        </li>
                    </div>
                </div>
            </ul >
        </div >
        : <></>
}

export default SideBarComponent


const AppsContainer = ({ apps, onClose }) => {
    return <>
        <img src={CloseIcon} className='apps-close-icon' onClick={onClose} />
        <div className='apps-title'>Applications</div>
        {/* <div>Search</div> */}
        <div className='apps-grid'>
            {apps.map(app =>

                <Link onClick={onClose} to={{
                    pathname: app.redirectTo,
                    state: app.state
                }}>
                    <div className='apps-icon-container' style={{ background: app.backgroundColor }}>
                        <img style={{ width: "100%" }} src={app.icon} />
                    </div>
                    <div className='apps-name'>{app.text}</div>
                </Link>
            )
            }

        </div>
    </>
}
import React, { useEffect, useState } from 'react'
import * as API from '../api.js';
import TableView from "../ReusableComponents/TableView/TableView";

const TeamAccess = ({ user, type }) => {

    const [loading, setLoading] = useState(true);
    const [allTeams, setAllTeams] = useState([]);
    const [allAccessProjects, setAllAccessProjects] = useState([]);
    const [allAccessDrones, setAllAccessDrones] = useState([]);

    const getTeamList = () => {
        setLoading(true)
        API.getTeamsList().then((data) => {
            const { teams } = data;
            setAllTeams(teams)
            setLoading(false)
        })
    }

    const getProjectList = () => {
        setLoading(true)
        API.getAccessProjectList().then(result => {
            setAllAccessProjects(result.projects)
            setAllAccessDrones(result.drones)
            setLoading(false)
        })
    }

    useEffect(() => {
        getTeamList()
        getProjectList()
    }, [])

    return (<div className="wrapper">
        <div style={{ padding: "10px 25px", width: "100%", gap: "20px" }}>
            <div style={{ width: "100%", height: "250px", overflow: 'hidden' }}>
                <div style={{ fontSize: "12px", fontWeight: "300", color: "#3c3c3c", padding: "10px 0px" }}>Owners</div>
                <div style={{ height: 'calc(100% - 40px' }}>
                    <TableView
                        isLoading={false}
                        onClickRow={() => { }}
                        columns={[
                            { Header: 'Team Name', accessor: 'teamName', width: "30%" },
                            { Header: 'Owner', accessor: 'ownerUserName', width: "70%" },
                        ]}
                        data={allTeams.filter(team => !team.isOwner).map(team => {
                            return {
                                ...team,
                                ownerUserName: team.teamOwner.username
                            }
                        })}
                        user={user}
                        onStateChange={(changedState) => {
                        }}
                    />
                </div>
            </div>
            <hr style={{ margin: '5px 0px' }} />
            <div style={{ width: "100%", height: "250px", overflow: 'hidden' }}>
                <div style={{ fontSize: "12px", fontWeight: "300", color: "#3c3c3c", padding: "10px 0px" }}>My Project Access</div>
                <div style={{ height: 'calc(100% - 40px' }}>
                    <TableView
                        isLoading={false}
                        onClickRow={() => { }}
                        columns={[
                            { Header: 'Project Name', accessor: 'project_name', width: "25%" },
                            { Header: 'Role', accessor: 'title', width: "25%" },
                            { Header: 'Team Name', accessor: 'team_name', width: "25%" },
                            { Header: 'Owner', accessor: 'owner_username', width: "25%" },
                        ]}
                        data={allAccessProjects}
                        user={user}
                        onStateChange={(changedState) => {
                        }}
                    />
                </div>
            </div>
            <hr style={{ margin: '5px 0px' }} />
            <div style={{ width: "100%", height: "250px", overflow: 'hidden' }}>
                <div style={{ fontSize: "12px", fontWeight: "300", color: "#3c3c3c", padding: "10px 0px" }}>My Drone Access</div>
                <div style={{ height: 'calc(100% - 40px' }}>
                    <TableView
                        isLoading={false}
                        onClickRow={() => { }}
                        columns={[
                            { Header: 'Drone Id', accessor: 'drone_id', width: "20%" },
                            { Header: 'UIN', accessor: 'uin', width: "20%" },
                            { Header: 'Role', accessor: 'title', width: "20%" },
                            { Header: 'Team Name', accessor: 'team_name', width: "20%" },
                            { Header: 'Owner', accessor: 'owner_username', width: "20%" },
                        ]}
                        data={allAccessDrones}
                        user={user}
                        onStateChange={(changedState) => {
                        }}
                    />
                </div>
            </div>
        </div>

    </div >)
}

export default TeamAccess
module.exports = {
    EXTENDBOUNDS: 0.00075,
    ORTHODETECTORTYPE: "ortho",
    tilesServer: process.env.REACT_APP_TILES_SERVER_URL,
    specialCharReg: /[!@#$%^&*()+\-`=\[\]{};':"\\|,.<>\/?]+/,
    SERVICES: {
        AEROGCS: 'AeroGCS Enterprise',
        DRONE_CONTROL_CENTER: "Drone Control Center"
    },
    COLORS: {
        PRIMARYCOLOR: "#2989CF",
        ANNOTATIONCOLOR: "#FF0000",
        COPYANNOTATIONCOLOR: "#fce514",
        STEPPERCOLOR: "#3c3c3c",
        STEPPERTEXTCOLOR: "white",
        CLASSSELECTEDCOLOR: "#2989CF26"
    },
    TASK: {
        STATUS: {
            CREATING: 'Creating',
            PREPARING: 'Preparing',
            PENDING: "Pending",
            IN_PROGRESS: "In progress",
            CREATED: "Created",
            FAILED: "Failed",
            COMPLETED: "Completed",
            MANUAL: "Manual",
        },
        TYPE: {
            TRAINING: 'Training',
            DETECTION: 'Detection',
            ANNREPORT: 'Annotation Report'
        }
    },

    EMPTY_IMAGESET_POPUP_MESSAGE: "( Empty or already processed imagesets will not appear in this list.)",
    EMPTY_PROJECT_POPUP_MESSAGE: "(You will only see projects that contain unprocessed imagesets.)",
    EMPTY_PLAN_POPUP_MESSAGE: "(You will only see plans that contain unprocessed imagesets.)",
    EMPTY_PROJECT_POPUP_MESSAGE_TRAINING: "(You will only see projects that contain unprocessed annotationsets.)",
    EMPTY_PLAN_POPUP_MESSAGE_TRAINING: "(You will only see plans that contain unprocessed annotationsets.)",

    POPUP_INPUT_TEXT_LIMIT: 50,
    OBJECT_NAME_LIMIT: 50,


    PAGES: [
        { DISPLAY_NAME: "Main dashboard", PAGE_NAME: "MAIN_DASHBOARD", ROUTE: "/" },
        { DISPLAY_NAME: "AeroGCS-E dashboard", PAGE_NAME: "AEROGCSE_DASHBOARD", ROUTE: "/" },
        { DISPLAY_NAME: "PicStork dashboard", PAGE_NAME: "PICSTORK_DASHBOARD", ROUTE: "/picstork/dashboard" },
        { DISPLAY_NAME: "DroneNaksha dashboard", PAGE_NAME: "DRONENAKSHA_DASHBOARD", ROUTE: "/dronenaksha/dashboard" },

        //AEROGCS-ENTERPRISE PAGES
        { DISPLAY_NAME: "AeroGCS-E Projects", PAGE_NAME: "AEROGCSE_PROJECTS", ROUTE: "/projects" },
        { DISPLAY_NAME: "AeroGCS-E Plans", PAGE_NAME: "AEROGCSE_PLANS", ROUTE: "/plans" },
        { DISPLAY_NAME: "AeroGCS-E Plan view", PAGE_NAME: "AEROGCSE_PLANVIEW", ROUTE: "/remote_mission/assignDroneToPlan" },
        { DISPLAY_NAME: "Plan flight logs", PAGE_NAME: "PLAN_FLIGHT_LOGS", ROUTE: "/remote_mission/flightLogs" },
        { DISPLAY_NAME: "AeroGCS-E Plan videos", PAGE_NAME: "AEROGCSE_VIDEOS", ROUTE: "/remote_mission/my_videos" },
        { DISPLAY_NAME: "AeroGCS-E Image Sets", PAGE_NAME: "AEROGCSE_IMAGE_SETS", ROUTE: "/remote_mission/image_collection" },
        { DISPLAY_NAME: "AeroGCS-E Images", PAGE_NAME: "AEROGCSE_IMAGES", ROUTE: "/remote_mission/my_images" },
        { DISPLAY_NAME: "Flight statistics", PAGE_NAME: "FLIGHT_STATISTICS", ROUTE: "/remote_mission/flights_statistics" },
        { DISPLAY_NAME: "All Flight Logs", PAGE_NAME: "ALL_FLIGHT_LOGS", ROUTE: "/remote_mission/all_flights_logs" },
        { DISPLAY_NAME: "Drone Control Center", PAGE_NAME: "DRONE_CONTROL_CENTER", ROUTE: "/drone_control_center/all_drones" },
        { DISPLAY_NAME: "Drone Performance", PAGE_NAME: "DRONE_PERFORMANCE", ROUTE: "/remote_mission/flights_completed_hours" },
        { DISPLAY_NAME: "Live stream videos", PAGE_NAME: "STREAM_VIDEOS", ROUTE: "/request_a_drone/all_videos" },
        { DISPLAY_NAME: "Flight Logs", PAGE_NAME: "FLIGHT_LOGS_BY_DRONEID", ROUTE: "/remote_mission/flight_logs_by_droneId" },
        { DISPLAY_NAME: "Flight Details", PAGE_NAME: "FLIGHT_DETAILS", ROUTE: "/remote_mission/flight_details" },
        { DISPLAY_NAME: "Flight Log Analyzer", PAGE_NAME: "FLIGHT_LOG_ANALYZER", ROUTE: "/flight_logAnalyzer" },
        { DISPLAY_NAME: "Flight Status Logs", PAGE_NAME: "FLIGHT_STATUS_LOGS", ROUTE: "/remote_mission/flight_status_logs" },
        { DISPLAY_NAME: "Detailed Logs", PAGE_NAME: "DETAILED_LOG", ROUTE: "/remote_mission/detailedlog" },
        { DISPLAY_NAME: "All Flight Plans ", PAGE_NAME: "ALL_FLIGHT_PLANS", ROUTE: "/allFlightPlans" },
        { DISPLAY_NAME: "Manage Drones", PAGE_NAME: "MANAGE_DRONES", ROUTE: "/drone_management/drones" },
        { DISPLAY_NAME: "Drones Details", PAGE_NAME: "DRONE_DETAILS", ROUTE: "/drone_management/drone_details" },
        { DISPLAY_NAME: "Select Location", PAGE_NAME: "SELECT_LOCATION", ROUTE: "/remote_mission/selectLocation" },
        { DISPLAY_NAME: "All Flights", PAGE_NAME: "ALL_FLIGHTS", ROUTE: "/aerogcsenterprise/flights" },
        { DISPLAY_NAME: "All Pilots", PAGE_NAME: "All_PILOTS", ROUTE: "/aerogcsenterprise/flights/pilots" },
        { DISPLAY_NAME: "Acres", PAGE_NAME: "ACRES", ROUTE: "/aerogcsenterprise/flights/acres" },
        { DISPLAY_NAME: "Plans", PAGE_NAME: "FLIGHT_PLAN", ROUTE: "/remote_mission/flightPlan" },
        { DISPLAY_NAME: "Survey Flight Plan", PAGE_NAME: "SURVEY_FLIGHT_PLAN", ROUTE: "/remote_mission/surveyFlightPlan" },
        { DISPLAY_NAME: "Survey Flight Plot", PAGE_NAME: "SPRAYING_FLIGHT_PLOT", ROUTE: "/remote_mission/sprayingFlightPlot" },
        { DISPLAY_NAME: "Survey Flight Plan", PAGE_NAME: "SPRAYING_FLIGHT_PLAN", ROUTE: "/remote_mission/sprayingFlightPlan" },
        { DISPLAY_NAME: "User Flight Locations", PAGE_NAME: "USER_FLIGHT_LOCATIONS", ROUTE: "/user_flight_locations" },
        { DISPLAY_NAME: "Teams", PAGE_NAME: "TEAMS", ROUTE: "/teams" },
        { DISPLAY_NAME: "Team Access", PAGE_NAME: "TEAM_ACCESS", ROUTE: "/teams/givenAccess" },
        { DISPLAY_NAME: "Profile Settings", PAGE_NAME: "PROFILE_SETTINGS", ROUTE: "/profile-settings" },
        { DISPLAY_NAME: "Plans", PAGE_NAME: "PLANS", ROUTE: "/aeromegh_plans" },
        { DISPLAY_NAME: "Usage Statistics", PAGE_NAME: "USAGE_STATISTICS", ROUTE: "/usage_statistics" },
        { DISPLAY_NAME: "User Statistics", PAGE_NAME: "USER_STATISTICS", ROUTE: "/analytictool/user_statistics" },
        { DISPLAY_NAME: "User Details", PAGE_NAME: "USER_DETAILS", ROUTE: "/analytictool/userdetails" },
        { DISPLAY_NAME: "User Tracking Logs", PAGE_NAME: "USER_TRACKING_LOGS", ROUTE: "/analytictool/user_tracking_logs" },
        { DISPLAY_NAME: "User Screen Logs", PAGE_NAME: "USER_SCREEN_LOGS", ROUTE: "/analytictool/user_screen_logs" },
        { DISPLAY_NAME: "Custom Statistic", PAGE_NAME: "CUSTOM_STATISTICS", ROUTE: "/analytictool/customStatistics" },

        //User 
        { DISPLAY_NAME: "Usage statistics", PAGE_NAME: "USAGE_STATISTICS", ROUTE: "/usage_statistics" },
        { DISPLAY_NAME: "User Profile", PAGE_NAME: "USER_PROFILE", ROUTE: "/profile-settings" },
        { DISPLAY_NAME: "User Login", PAGE_NAME: "USER_LOGIN", ROUTE: "/login" },
        { DISPLAY_NAME: "User Logout", PAGE_NAME: "USER_LOGOUT", ROUTE: "/logout" }
    ],

    BLOB_URL: process.env.REACT_APP_AZURE_BLOB_URL,


    MAP_COLORS: ['#FF6633', '#FFB399', '#FF33FF', '#FFFF99', '#00B3E6',
        '#E6B333', '#3366E6', '#999966', '#99FF99', '#B34D4D',
        '#80B300', '#809900', '#E6B3B3', '#6680B3', '#66991A',
        '#FF99E6', '#CCFF1A', '#FF1A66', '#E6331A', '#33FFCC',
        '#66994D', '#B366CC', '#6666FF', '#B33300', '#CC80CC',
        '#66664D', '#991AFF', '#E666FF', '#4DB3FF', '#1AB399',
        '#E666B3', '#33991A', '#CC9999', '#B3B31A', '#00E680',
        '#4D8066', '#809980', '#E6FF80', '#1AFF33', '#999933',
        '#FF3380', '#CCCC00', '#66E64D', '#4D80CC', '#9900B3',
        '#E64D66', '#4DB380', '#FF4D4D', '#99E6E6', '#4D8000'],
    PICSTORK_PLAN_NAME: 'PICSTORK_PLAN',

    //React joyride constants 
    TRIGGER_DELAY: 1000,
    FLOATER_PROPS: { disableAnimation: true },
    NEXT_BUTTON: {
        fontSize: '14px',
        content: 'Step',
        background: 'none',
        color: '#2989cf',
    },
    SKIP_BUTTON: {
        fontSize: '14px',
        content: 'Step',
        background: 'none',
        color: '#2989cf',
    },
    MAIN_OPTIONS: {
        arrowColor: '#fff',
        primaryColor: '#2989cf',
        textColor: '#3c3c3c',
        font: "popins",
        width: "100%",
        zIndex: 1000,
        beaconSize: 35,
        overlayColor: 'rgba(0, 0, 0, 0.7)',
    },


    TEXTSTYLING: { textAlign: 'left', fontSize: '12px', height: '20px', color: '#3c3c3c' },
    TITLESTYLING: { textAlign: 'left', paddingLeft: '10px', fontSize: '16px' },


    IMAGE_SET_RENAME_VARIABLE: "_$PDRL",
    STEPPER: {
        STEPPERWIDTH: "260px"
    },




    PLANNER: {
        STATUS: {
            TO_DO: "#FF6161",
            IN_PROGRESS: "#FFE24B",
            COMPLETED: "#43CAC8",
        }
    },

    MAP_URLS: {
        ROAD: 'https://mt0.google.com/vt/lyrs=h&hl=en&x={x}&y={y}&z={z}',
        ROAD_MAP: 'https://mt0.google.com/vt/lyrs=m&hl=en&x={x}&y={y}&z={z}',
        TERRAIN: 'https://mt0.google.com/vt/lyrs=p&hl=en&x={x}&y={y}&z={z}',
        ALTER_ROAD: 'https://mt0.google.com/vt/lyrs=r&hl=en&x={x}&y={y}&z={z}',
        SATELITE: 'https://mt0.google.com/vt/lyrs=s&hl=en&x={x}&y={y}&z={z}',
        TERREN_ONLY: 'https://mt0.google.com/vt/lyrs=t&hl=en&x={x}&y={y}&z={z}',
        HYBRID: 'https://mt0.google.com/vt/lyrs=y&hl=en&x={x}&y={y}&z={z}',
    },


    SUBSCRIPTOPTIONS: {
        ITEMS: {
            STORAGE: "storage",
            ANALYTICS_CREDITS: "analytics",
            PROCESSING_CREDITS: "processing"
        },
        PRODUCT_TYPES: {
            SERVICES_PLAN: "services_plan",
            TASKS_CREDITS: "tasks_credits"
        }
    },
    BUY_NEW_DRONES: "BUY_NEW_DRONES",
    BUY_MORE_DRONES: "BUY_MORE_DRONES",
    CANCEL_DUE_PAYMENT: "CANCEL_DUE_PAYMENT",
    CANCEL_SUBSCRIPTION: "CANCEL_SUBSCRIPTION",
    CANCEL_DRONE_ADDON: "CANCEL_DRONE_ADDON",
    SOFTWARES: {
        GREEN: "GREEN",
        ORANGE: "ORANGE",
        FIRMWARE: "FIRMWARE"
    },
    OEM_USERS: [
        "suraj.bhalerao@pdrl.in",
        "chetanpdrl@yahoo.com",
        "chetanpdrl1@yahoo.com"
    ]
}


